/**
 * Scenario Angle
 *
 *   x 0 1 2 3 4 5 6
 *   7 x 8 . . . . .
 *   9 . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *
 */

export default class ScenarioAngle {
    name = 'angle';
    weight = 5;
    schemes;

    constructor() {
        this.schemes = [
            {
                a: [ 1, 2, 3, 4, 5, 6, 7, 8,10,16]
            },
            {
                a: [ 8,16,24,32,40,48,56, 1,17, 2]
            },
            {
                a: [15,23,31,39,47,55,63, 6,22, 5]
            },
            {
                a: [ 6, 5, 4, 3, 2, 1, 0,15,13,23]
            },
            {
                a: [62,61,60,59,58,57,56,55,53,47]
            },
            {
                a: [55,47,39,31,23,15, 7,62,46,61]
            },
            {
                a: [48,40,32,24,16, 8, 0,57,41,58]
            },
            {
                a: [57,58,59,60,61,62,63,48,50,40]
            },
        ];


        this.conditions = [
            {
                a: { isEmpty: [ 0, 9] }
            },
            {
                a: { isEmpty: [ 0, 9] }
            },
            {
                a: { isEmpty: [ 7,14] }
            },
            {
                a: { isEmpty: [ 7,14] }
            },
            {
                a: { isEmpty: [54,63] }
            },
            {
                a: { isEmpty: [54,63] }
            },
            {
                a: { isEmpty: [49,56] }
            },
            {
                a: { isEmpty: [49,56] }
            },
        ]
    }

}