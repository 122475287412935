import React, {Component} from 'react'
import {connect} from 'react-redux'
import GameLoader from '../../../game/GameLoader'
import ReactGA from "react-ga";

class Loader extends Component {


    loadGame() {
        this.props.setGameMode('show');
        const gameLoader = new GameLoader();
        gameLoader.loadGame();
    }

    render() {

        if (
            this.props.loadedGame.moves.length
            || this.props.history.length > 0
            || this.props.gameMode === 'train'
        ) {
            return <button className="Loader Btn icon-crown" disabled="disabled" >Masters</button>
        } else {
            return <button className="Loader Btn icon-crown" onClick={() => this.loadGame()} >Masters</button>
        }
    }
}

const mapStateToProps = state => {
    return {
        loadedGame: state.loadedGame,
        gameMode: state.gameMode,
        accounts: state.accounts,
        history: state.history
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setGameMode: (gameMode => {
            ReactGA.event({
                category: 'User',
                action: 'Starts watching master play'
            });
            dispatch({type: 'SET_GAME_MODE', gameMode: gameMode})
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Loader)
