/**
 * Scenario Hammer
 *
 * Beginning scenario with baseline and edge keys empty.
 *
 *   x x x x x x x x
 *   . x 1 2 3 4 x .
 *   . . 5 6 7 8 . .
 *   . . . 9 0 . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *
 */

export default class ScenarioHammer {
    name = 'hammer';
    schemes;
    weight = 3;
    conditions;

    constructor() {
        this.schemes = [
            {
                a: [10,11,12,13,18,19,20,21,27,28],
                b: [13,12,11,10,21,20,19,18,28,27]
            },
            {
                a: [22,30,38,46,21,29,37,45,28,36],
                b: [46,38,30,22,45,37,29,21,36,28]
            },
            {
                a: [53,52,51,50,45,44,43,42,36,35],
                b: [50,51,52,53,42,43,44,45,35,36]
            },
            {
                a: [41,33,25,17,42,34,26,18,35,27],
                b: [17,25,33,41,18,26,34,42,27,35]
            },
        ];
        this.conditions = [
            {
                a: { isEmpty: [0,1,2,3,4,5,6,7,9,14] },
                b: { isEmpty: [0,1,2,3,4,5,6,7,9,14] }
            },
            {
                a: { isEmpty: [7,15,23,31,39,47,55,63,14,54] },
                b: { isEmpty: [7,15,23,31,39,47,55,63,14,54] }
            },
            {
                a: { isEmpty: [56,57,58,59,60,61,62,63,49,54] },
                b: { isEmpty: [56,57,58,59,60,61,62,63,49,54] }
            },
            {
                a: { isEmpty: [0,8,16,24,32,40,48,56,9,49] },
                b: { isEmpty: [0,8,16,24,32,40,48,56,9,49] }
            },
        ]
    }

}