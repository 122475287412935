// Das soll mal eine Interface werden.
import { dispToMove } from '../../tools';

import ScenarioAngle from './Scenarios/ScenarioAngle';
import ScenarioAxt from './Scenarios/ScenarioAxt';
import ScenarioBirds from "./Scenarios/ScenarioBirds";
import ScenarioEdge from './Scenarios/ScenarioEdge';
import ScenarioFork from './Scenarios/ScenarioFork';
import ScenarioHammer from './Scenarios/ScenarioHammer';
import ScenarioLotto from './Scenarios/ScenarioLotto';
import ScenarioMidway from './Scenarios/ScenarioMidway';
import ScenarioPlato from './Scenarios/ScenarioPlato';
import ScenarioSide from './Scenarios/ScenarioSide';
import ScenarioWonder from './Scenarios/ScenarioWonder'

import ConditionManager from './ConditionManager';

export const TYPES = {
    ANGLE: 'angle',
    AXT: 'axt',
    BIRDS: 'birds',
    EDGE: 'edge',
    FORK: 'fork',
    HAMMER: 'hammer',
    LOTTO: 'lotto',
    MIDWAY: 'midway',
    PLATO: 'plato',
    SIDE: 'side',
    WONDER: 'wonder',
};

export default class ScenarioManager {
    services;
    normDispo;
    conditionManager;

    constructor(disposition, toMove) {
        if(typeof disposition !== 'undefined' && typeof toMove !== 'undefined') {
            this.normDispo = dispToMove(disposition, toMove);
        }
        this.services = {
            [TYPES.ANGLE]: new ScenarioAngle(),
            [TYPES.AXT]: new ScenarioAxt(),
            [TYPES.BIRDS]: new ScenarioBirds(),
            [TYPES.EDGE]: new ScenarioEdge(),
            [TYPES.FORK]: new ScenarioFork(),
            [TYPES.HAMMER]: new ScenarioHammer(),
            [TYPES.LOTTO]: new ScenarioLotto(),
            [TYPES.MIDWAY]: new ScenarioMidway(),
            [TYPES.PLATO]: new ScenarioPlato(),
            [TYPES.SIDE]: new ScenarioSide(),
            [TYPES.WONDER]: new ScenarioWonder(),
        };
        this.conditionManager = new ConditionManager(this.normDispo, this.services);
    }

    getSchemed(data, type) {
        if (typeof type !== 'undefined') {
            return {
                type: type,
                schemes: this.services[type].getSchemed(data)
            }
        }
        return Object.keys(this.services).map(type => { return {
            type: type,
            schemes: this.services[type].getSchemed(data)
        }})
    }

    /**
     *
     */
    getWeight(scheme) {
        if((scheme in this.services) && this.services[scheme].weight !== 'undefined') {
            return this.services[scheme].weight;
        }
        return 1
    }

    /**
     * Returns full experience data from DB search.
     *
     * @param moves array
     * @returns {Array}
     */
    getMoveScenarios(moves) {
        let experience = [];
        for(let i =0; i < moves.length; i++) {
            experience.push( this.getScenarioObjects(moves[i]) );
        }
        return experience;
    }

    /**
     *
     * @param move
     * @returns {Array}
     *
     * {
     *     move: move, // (param)
     *     appears: [
     *         {
     *             scheme: "axt"
     *             search: "0120120120"
     *             moveAlias: "m3"
     *         }
     *     ]
     * }
     */
    getScenarioObjects(move) {
        let appears = Object.keys(this.services).map(type => {
            let schemeCollection = [];
            for(let i = 0; i < this.services[type].schemes.length; i++) {

                if(this.services[type].schemes[i].a.indexOf(move) >= 0) {
                    let symmetrics = Object.keys(this.services[type].schemes[i]).map(abc => {
                        let search = this.services[type].schemes[i][abc].map(pos =>
                            this.normDispo.charAt(pos)
                        );

                        // check conditions with disposition.
                        if(this.conditionManager.check(type, i, abc)) {
                            return {
                                scheme: type,
                                search: search.join(''),
                                moveAlias: 'm' + this.services[type].schemes[i][abc].indexOf(move)
                            }
                        }
                        else return null;
                    });
                    let result = symmetrics.filter(item => item !== null);
                    result.sort((a,b) => {return parseInt(a.search,3) - parseInt(b.search,3)});
                    schemeCollection.push(result[0])
                }
            }
            return schemeCollection;
        });

        appears = appears.filter(item => item.length > 0);
        let allSchemes = [];
        for(let i = 0; i < appears.length; i++) {
            for(let k = 0; k < appears[i].length; k++) {
                if (appears[i][k]) {
                    allSchemes.push(appears[i][k])
                }
            }
        }

        return {
            move: move,
            appears: allSchemes
        }
    }

    /**
     *
     * @param histItem
     * @param winner string
     * @param type string
     *
     * @type {{scheme: string, disposition: string, move: number, wins: boolean}}
     * @return object {
            scheme: 'edge',
            disposition: '2222222202',
            move: 8,
            wins: true
        };
     */
    getSchemedMoves(histItem, winner, type) {
        if (typeof type !== 'undefined') {
            const schemes = this.services[type].schemes;
            return [this.extractSchemedMoves(histItem, winner, schemes, type)]
        }
        return Object.keys(this.services).map(type => {
            const schemes = this.services[type].schemes;
            return this.extractSchemedMoves(histItem, winner, schemes, type)
        })
    }


    extractSchemedMoves(histItem, winner, schemes, type) {
        let schemed = [];
        for (let symmetrics of schemes) {
            if(symmetrics.a.indexOf(histItem.move) >= 0) {
                const disposition = dispToMove(histItem.state, histItem.toMove);

                let iterator = ["a","b","c","d","e","f","g","h","i","j"];
                let collector = [];
                for(let i = 0; typeof symmetrics[iterator[i]] !== 'undefined'; i++) {
                    let situation = symmetrics[iterator[i]].map(
                        (item) => disposition.charAt(item)
                    );
                    collector.push({
                        scheme: type,
                        disposition: situation.join(''),
                        move: symmetrics[iterator[i]].indexOf(histItem.move),
                        wins: winner === histItem.toMove
                    });
                }
                collector.sort((a, b) => {return (parseInt(a.disposition, 3) - parseInt(b.disposition, 3))});
                if(collector.length) {
                    const bestDisp = collector[0].disposition;
                    let moves = [];
                    for(let i = 0; i < collector.length; i++) {
                        if (collector[i].disposition === bestDisp && moves.indexOf(collector[i].move) === -1) {
                            moves.push(collector[i].move);
                            schemed.push(collector[i]);
                        }
                    }
                }

            }
        }
        return schemed;
    }

}