/**
 * Scenario Lotto
 *
 *   x x x . . . . .
 *   x 1 3 . . . . .
 *   x 2 4 6 . . . .
 *   . . 5 7 9 . . .
 *   . . . 8 0 . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *
 */

export default class ScenarioLotto {
    name = 'lotto';
    weight = 3;
    schemes;
    conditions;

    constructor() {
        this.schemes = [
            {
                a: [ 9,17,10,18,26,19,27,35,28,36],
                b: [ 9,10,17,18,19,26,27,28,35,36]
            },
            {
                a: [14,13,22,21,20,29,28,27,36,35],
                b: [14,22,13,21,29,20,28,36,27,35]
            },
            {
                a: [54,46,53,45,37,44,36,28,35,27],
                b: [54,53,46,45,44,37,36,35,28,27]
            },
            {
                a: [49,50,41,42,43,34,35,36,27,28],
                b: [49,41,50,42,34,43,35,27,36,28]
            },
        ];

        this.conditions = [
            {
                a: { isEmpty: [0,1,2,8,16] },
                b: { isEmpty: [0,1,2,8,16] }
            },
            {
                a: { isEmpty: [5,6,7,15,23] },
                b: { isEmpty: [5,6,7,15,23] }
            },
            {
                a: { isEmpty: [47,55,63,62,61] },
                b: { isEmpty: [47,55,63,62,61] }
            },
            {
                a: { isEmpty: [58,57,56,48,40] },
                b: { isEmpty: [58,57,56,48,40] }
            },
        ]
    }

}