/**
 * Check all positions (given in needles) in normDispo if
 * value is "0" and return false if at least one is not "0"
 *
 * @param normDispo string
 *   A complete game normDispo.
 *   I.e. 0000012020112...21212
 * @param needles array
 *   Array of integer positions to check.
 *   [23, 34, ... {0-63}]
 * @returns {boolean}
 *
 */
const isEmpty = (normDispo, needles) => {
    for (let i = 0; i < needles.length; i++) {
        if (normDispo.charAt(needles[i]) !== "0") {
            return false;
        }
    }
    return true
};

export default isEmpty;