import React, {Component} from 'react';
import {connect} from 'react-redux';
import crypto from 'crypto-js'

import './Auth.scss';

class Auth extends Component {

    handleSubmit(event) {
        event.preventDefault();
        const wordArray = crypto.SHA256(event.target.elements.password.value);
        const hash = wordArray.toString(crypto.enc.Base64);
        if(hash === '+mGblC17Z9M52TSfxi+tUFu9YPMWdV/pcFGJcMKvvvQ=') {
            this.props.setLoginState(true)
            this.props.authSuccess()
        }
    }

    render() {
        return (!this.props.loggedIn)
            ? <div className="Auth">
                <form onSubmit={event => {this.handleSubmit(event);}}>
                    <div className="form-element fe-password">
                        <label>Password:</label>
                        <input type="password" name="password" />
                    </div>
                    <button className="Btn" type="submit">Submit</button>
                </form>
            </div>
            : <div className="Auth">
                <div className="message">
                    <blockquote>
                        You are logged in! <br />
                        So don't mess around, ok?
                    </blockquote>
                </div>
            </div>
    }
}


const mapStateToProps = state => {
    return {
        loggedIn: state.loggedIn
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLoginState: (loggedIn) => dispatch({type: 'SET_LOGIN_STATE', loggedIn: loggedIn})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth)