/**
 * Scenario "Midway"
 *
 *   1 . . . . . . .
 *   . 2 . . . . . .
 *   . . 3 . . . . .
 *   . . . 4 . . . .
 *   . . . . 5 . . .
 *   . . . . . 6 . .
 *   . . . . . . 7 .
 *   . . . . . . . 8
 *
 */

export default class ScenarioMidway {
    name = 'midway';
    weight = 5;
    schemes;

    constructor() {
        this.schemes = [
            {
                a: [ 0, 9,18,27,36,45,54,63],
                b: [63,54,45,36,27,18, 9, 0]
            },
            {
                a: [ 7,14,21,28,35,42,49,56],
                b: [56,49,42,35,28,21,14, 7]
            }
        ];
    }

}