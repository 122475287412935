import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';

class NewGame extends Component {

    render() {
        return <button
            className="NewGame Btn"
            onClick={this.props.startNewGame} >New Game</button>
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startNewGame: () => {
            ReactGA.event({
                category: 'User',
                action: 'Starts new game'
            });
            dispatch({type: 'START_NEW_GAME'})
        }
    }
};

export default connect(null, mapDispatchToProps)(NewGame);
