import ScenarioManager from './Scenario/ScenarioManager'
import { stringifyDisp } from "../../game/tools";
import { getAllPossibleMoves, setToken } from "../game";
import { posToXy } from '../tools';
import axios from '../../axios-content';
import store from '../../store/store';


class SolutionFinder {
    disposition;
    allMoveOptions;
    scenarioManager;
    tips;

    constructor() {
        const state = store.getState();
        this.disposition = stringifyDisp(state.disposition);

        /**
         * WARNING: Please don't confuse
         *   state.disposition ARRAY
         *   and
         *   this.disposition STRING
         */
        // Get all available moves from Disposition.
        this.allMoveOptions = getAllPossibleMoves(state.disposition, state.toMove);

        // Init scenarioManager.
        this.scenarioManager = new ScenarioManager(this.disposition, state.toMove);
        this.tips = state.tips;
    }

    /**
     * Gets win/lose data from database and makes it comparable.
     *
     * @param data array
     *   Raw data from database.
     *
     * @returns array
     *   Comparable and ordered data array.
     *   I.e. [{move: 46, result: 0.8725}, ...]
     */
    evalSolutionData = (data) => {
        let results = data.map(move => {

            // Collect weighted data from query result in an array.
            let collector = [];
            for (let i = 0; i < move.appears.length; i++) {
                if (typeof move.appears[i].result !== 'undefined') {
                    const mw = parseInt(move.appears[i].result.win, 10);
                    const ml = parseInt(move.appears[i].result.lose, 10);
                    const denominator = mw + ml;
                    const mres = mw / denominator;

                    // Get weight from scheme and add several times to result.
                    const weight = this.scenarioManager.getWeight(move.appears[i].scheme);
                    for (let j = 0; j < weight; j++) {
                        collector.push(mres);
                    }
                }
            }

            // Calc rating of move by experience data.
            let rate = 0.5; // Neutral element.
            if (collector.length) {
                let win = 0;
                for (let i = 0; i < collector.length; i++) {
                    win = win + collector[i];
                }
                rate = win / collector.length;
            }

            return {
                move: move.move,
                result: rate
            }
        });

        results.sort((a, b) => {
            return b.result - a.result
        });
        
        return results;
    };

    /**
     * Returns the best results move ID.
     * If result is empty method will return a random move ID.
     *
     * @param results
     *  Results from this.evalSolutionData.
     * @param allMoveOptions
     *  Possible moves to get random solution.
     *
     * @returns {int|boolean}
     *  Return position ID for net move.
     */
    getSolution(results, allMoveOptions) {

        // Result if no data available.
        let solution = false;
        if(results.length > 0) {
            solution = results[0].move;
        } else {
            // Give random solution, if no experience data found.
            solution = (allMoveOptions.length)
                ? allMoveOptions[Math.floor(Math.random()*allMoveOptions.length)]
                : false;
        }

        return solution;
    };

    /**
     * Returns best results to game-Controller.
     *
     * @returns int[64]
     */
    initSolution = (execMove) => {
        // Get matching Scenarios for all move options.
        let moveScenarios = this.scenarioManager.getMoveScenarios(this.allMoveOptions);

        if (moveScenarios.length) {
            // Load schemed solution data from store.
            return axios.post('/get-solution', {
                data: moveScenarios
            }).then(result => {
                const validatedMoves = this.evalSolutionData(result.data, this.allMoveOptions);
                if (execMove) {
                    // Move to be executed.
                    const solution = this.getSolution(validatedMoves, this.allMoveOptions);
                    const xy = posToXy(solution);
                    setToken(xy.x, xy.y);
                } else {
                    // Give player tip for his next move.
                    this.setTips(validatedMoves, result.data);
                }
            }).catch(err => {
                console.log(err);
            });

        } else
            return false;

    };

    /**
     * Set tips for Player on board to make result visible.
     *
     * @param validatedMoves
     *   validated moves.
     *
     * @returns {boolean}
     *   Return false if no tips are given.
     *
     * @dispatch SET_TIPS
     */
    setTips(validatedMoves, data) {
        if (validatedMoves.length === 0) { return false; }
        let tips = {};
        for(let i = 0; i < validatedMoves.length; i++) {
            tips[validatedMoves[i].move] = Math.round(validatedMoves[i].result * 100) + '%';
        }
        store.dispatch({type: 'SET_TIPS', tips: tips, tipsTable: data});
    }
}

export default SolutionFinder;

