/**
 * Scenario Wonder
 *   Just an amplifier.
 *
 *   1 . . . . . . 2
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   3 . . . . . . 4
 *
 */

export default class ScenarioWonder {
    name = 'wonder';
    weight = 10;
    schemes;

    constructor() {
        this.schemes = [
            {
                a: [ 0, 7,56,63],
                b: [ 0,56, 7,63],
                c: [ 7, 0,63,56],
                d: [ 7,63, 0,56],
                e: [63,56, 7, 0],
                f: [63, 7,56, 0],
                g: [56, 0,63, 7],
                h: [56,63, 0, 7]
            }
        ];
    }

}
