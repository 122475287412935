import React, {Component} from 'react';
import './Ruler.scss';

class Ruler extends Component {

  render() {
    let inches = [];
    for (let i = 0; i < 8; i++) {
      inches.push(<li key={i}>{this.props.scale.charAt(i)}</li>)
    }

    return <ul className={['Ruler', [...this.props.classes]].join(' ')}>{inches}</ul>
  };
}

export default Ruler;
