import React, { Component } from 'react';
import './Cell.scss';

class Cell extends Component {
    render() {
        let classes = this.props.classes;
        classes.push('Cell');
        return <div className={classes.join(' ')} onClick={this.props.clicked} >
            {this.props.children}
        </div>
    }
}

export default Cell;