/**
 * Check if positions (given in needles) are in the color of
 * account to move.
 *
 * @param normDispo string
 *   A complete game normDispo.
 *   I.e. 0000012020112...21212
 * @param needles array
 *   Array of integer positions to check.
 *   [23, 34, ... {0-63}]
 * 
 * @returns {boolean}
 *
 */
const isMine = (normDispo, needles) => {
    for (let i = 0; i < needles.length; i++) {
        if (normDispo.charAt(needles[i]) !== "1") {
            return false;
        }
    }
    return true
};

export default isMine;