/**
 * Scenario ScenarioPlato
 *
 *   x 0 1 2 3 4 5 x
 *   . . 6 7 8 9 . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *
 */

export default class ScenarioPlato {
    name = 'plato';
    weight = 5;
    schemes;

    constructor() {
        this.schemes = [
            {
                a: [ 1, 2, 3, 4, 5, 6,10,11,12,13],
                b: [ 6, 5, 4, 3, 2, 1,13,12,11,10]
            },
            {
                a: [15,23,31,39,47,55,22,30,38,46],
                b: [55,47,39,31,23,15,46,38,30,22]
            },
            {
                a: [62,61,60,59,58,57,53,52,51,50],
                b: [57,58,59,60,61,62,50,51,52,53]
            },
            {
                a: [48,40,32,24,16, 8,41,33,25,17],
                b: [ 8,16,24,32,40,48,17,25,33,41]
            },
        ];

        this.conditions = [
            {
                a: { isEmpty: [0,7] },
                b: { isEmpty: [0,7] }
            },
            {
                a: { isEmpty: [7,63] },
                b: { isEmpty: [7,63] }
            },
            {
                a: { isEmpty: [63,56] },
                b: { isEmpty: [63,56] }
            },
            {
                a: { isEmpty: [56,0] },
                b: { isEmpty: [56,0] }
            },
        ]
    }

}
