import axios from '../axios-content';

class database {
    requestData;

    constructor(data) {
        this.requestData = data;
    }

    storeMoves(callback) {
        // Save schemed moves to store.
        axios.post('/store-moves', {
            data: this.requestData
        }).then(result => {
            callback(result);
        }).catch(err => {
            console.log(err);
        });
    }

    storeGames(callback) {
        // Save schemed moves to store.
        axios.post('/store-games', {
            data: this.requestData
        }).then(result => {
            callback(result);
        }).catch(err => {
            console.log(err);
        });
    }


    loadGame(callback) {
        // Save schemed moves to store.
        axios.post('/get-game', {
            ...this.requestData
        }).then(result => {
            callback(result);
        }).catch(err => {
            console.log(err);
        });
    }

}

export default database;