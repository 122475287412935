/**
 * Normalize disposition so that "1" is always the color to move.
 * Used for storing experiences, where it doesn't make a difference
 * if color is white or black.
 *
 * @param disposition string
 *   The real disposition from game.
 * @param toMove
 *   Account id to move.
 *
 * @return
 *   The normalized disposition for storing experience.
 */
const dispToMove = (disposition, toMove) => {
    if (toMove === 2) {
        let newDisp = '';
        for (let i = 0; i < disposition.length; i++) {
            switch(disposition.charAt(i)) {
                case '1':
                    newDisp = newDisp.concat('2');
                    break;
                case '2':
                    newDisp = newDisp.concat('1');
                    break;
                default:
                    newDisp = newDisp.concat('0');
            }
        }
        disposition = newDisp;
    }
    return disposition;
};

/**
 * Get x/y coordinates from position number.
 *
 * @param position
 *   Position number 0-63.
 *
 * @returns {{x: number, y: number}}
 *   Object of x/y coordinates.
 */
const posToXy = (position) => {
    position = parseInt(position, 10);
    if(position >= 0 && position <= 63) {
        return {
            x: position % 8,
            y: Math.floor(position / 8),
        }
    }
};

/**
 * Stringify disposition array.
 *
 * @param disposition array
 *   Disposition as modelled in initial state.
 *   Array of 8 arrays, each with 8 field values.
 *
 * @returns {string}
 *   Stringified disposition.
 */
const stringifyDisp = (disposition) => {
    let stringifiedDisp = '';
    for(let i = 0; i < 8; i++) {
        stringifiedDisp = stringifiedDisp.concat(disposition[i].join(''));
    }
    return stringifiedDisp;
};

/**
 * Un-stringify disposition string to array, used in history.
 *
 * @param disposition string
 *   Disposition as string(64) "00012010201020222100...".
 *
 * @returns {array}
 *   Disposition.
 */
const unStringifyDisp = (disposition) => {
    return disposition.match(/[0-2]{8}/g).map(line => {
        return line.split('').map(token => parseInt(token, 10));
    })
};

/**
 * Convert alpha numerical notation string to array of indexes.
 *
 * @param alphaNum
 *   Alpha numerical notation string.
 *   I.e. f5d6e7...
 *
 * @returns {number[]}
 */
const alphaNumToIndex = (alphaNum) => {
    let position = alphaNum.match(/([a-h][1-8])/g);
    return position.map(move => {
        const char = move.split('');
        const x = "abcdefgh".indexOf(char[0]);
        const y = parseInt(char[1], 10) - 1;
        return y * 8 + x;
    });
};

/**
 * Convert index to alpha numerical notation.
 *
 * @param index integer
 *   Field index 0-63.
 *
 * @returns {string}
 */
const indexToAlphaNum = (index) => {
    const posXY = posToXy(index);
    return "ABCDEFGH".charAt(posXY.x) + (posXY.y+1);
};

/**
 * Returns string with first char uppercase.
 *
 * @param string
 * @returns {string}
 */
function ucfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export {
    alphaNumToIndex,
    dispToMove,
    indexToAlphaNum,
    posToXy,
    stringifyDisp,
    ucfirst,
    unStringifyDisp
}