import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveExperience } from '../../../game/SolutionFinder/ExperienceStore';
import './Win.scss';
import ReactGA from "react-ga";

class Win extends Component {

    componentDidMount = () => {
        saveExperience(this.props.history, this.props.winner);
    };

    render = () => {
        if (this.props.winner) {
            return <div className={['Winner', this.props.color].join(' ')}>
                <span>{this.props.text}</span>
            </div>
        }
    }
}

const mapStateToProps = state => {

    let color = '';
    let text = '';
    if (state.winner) {
        switch (state.winner) {
          case 1:
            color = 'White';
            text = 'White wins!';
            break;
          case 2:
            color = 'Black';
            text = 'Black wins!';
            break;
          case 3:
            color = 'Remis';
            text = 'Remis!';
            break;
          default:
        }
        ReactGA.event({
          category: 'User',
          action: text
        });
    }
    return {
        color: color,
        text: text,
        winner: state.winner,
        history: state.history,
    }
};

export default connect(mapStateToProps)(Win);