import React, { Component } from 'react';
import { connect } from 'react-redux';

import './ShowHist.scss';

class ShowHist extends Component {

  render() {
    let classes  = ['ShowHist', 'Btn', 'icon-list'];
    if (this.props.showHist) {classes.push('active')}
    return <button
      className={classes.join(' ')}
      onClick={() => this.props.toggleHistory(this.props.showHist)} >
      History
    </button>
  }
}

const mapStateToProps = state => {
  return {
    showHist: state.showHist
  }
};

const mapDispatchToProps = dispatch => {
  return {
    toggleHistory: (currShowHist) => {
      dispatch({type: 'TOGGLE_HISTORY', showHist: !currShowHist})
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowHist);