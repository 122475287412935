import ScenarioManager from './Scenario/ScenarioManager';
import database from '../../store/database';
import store from '../../store/store';
import GameLoader from '../GameLoader'

/**
 * Starts the extraction of schemed moves and saves the result.
 * Is triggered at the end of the game. May be also, when edges
 * are occupied.
 *
 * @param history
 *   The game history.
 * @param winner
 *
 */
const saveExperience = (history, winner) => {
    const prevState = store.getState();

    if(prevState.storeMoves) {
        const scenarioManager = new ScenarioManager();

        let collector = [];
        for(let i = 0; i < history.length; i++) {
            const histItem = history[i];

            const schemed = scenarioManager.getSchemedMoves(histItem, winner);

            for(let i = 0; i < schemed.length; i++) {
                if (schemed[i].length) {
                    collector = collector.concat(schemed[i]);
                }
            }
        }

        let db = new database(collector);
        db.storeMoves((result) => {
            if (result.status === 200) {
                store.dispatch({
                    type: 'MESSAGE',
                    level: 'status',
                    content: 'Game stored successful.'
                });

                const state = store.getState();

                // Quit recent import and load next.
                if (state.gameMode === 'train') {

                    if(state.trainMode === 'masters') {
                        const recent_id = state.loadedGame.id;
                        store.dispatch({ type: 'START_NEW_GAME' });

                        const gameLoader = new GameLoader();
                        gameLoader.loadGame(recent_id);
                    } else if (state.trainMode === 'self') {
                        store.dispatch({
                            type: 'START_SELF_TRAIN',
                            loggedIn: state.loggedIn,
                            gameMode: 'train',
                            trainMode: 'self'
                        })

                    }
                }
            }

        });
    } else {

        store.dispatch({
            type: 'MESSAGE',
            level: 'warn',
            content: 'Game is already stored.'
        });
    }

};

export {
    saveExperience
}