
const initialState = {
    loggedIn: false,
    devMode: false,
    versionImport: 2,
    storeMoves: true,
    gameMode: 'play', // valid: play, train, show
    trainMode: 'pause', // valid: masters, self, stopped
    showMode: 'step', // valid: step, run
    loadedGame: {
        id: null,
        moves: [],
        position: 0,
    },
    timer: null,
    message: {},
    toMove: 2,
    disposition: [
        [0,0,0,0,0,0,0,0],
        [0,0,0,0,0,0,0,0],
        [0,0,0,0,0,0,0,0],
        [0,0,0,1,2,0,0,0],
        [0,0,0,2,1,0,0,0],
        [0,0,0,0,0,0,0,0],
        [0,0,0,0,0,0,0,0],
        [0,0,0,0,0,0,0,0],
    ],
    prevDisposition: [],
    possibleMoves: [19, 26, 37, 44],
    tips: {},
    tipsTable: [],
    blocked: [],
    gameOver: false,
    winner: 0,
    showHist: false,
    history: [],
    accounts: [
        {
            id: 1,
            name: 'White',
            computed: true,
            status: 2,
            disabled: false
        },
        {
            id: 2,
            name: 'Black',
            computed: false,
            status: 2,
            disabled: false
        }
    ]
};

const reducer = ( state = initialState, action ) => {
    let unchanged;
    let interim = { ...state };
    switch ( action.type ) {
        case 'CREATE_GAME_MOVES':
        case 'LOAD_GAMES':
        case 'PLAYER_MOVES':
        case 'REWIND_HISTORY':
        case 'SET_GAME_MODE':
        case 'SET_LOGIN_STATE':
        case 'SET_TIPS':
        case 'SHOW_RUN_PAUSE':
        case 'START_TRAIN':
        case 'STOP_TRAIN':
        case 'SWITCH_DEV_MODE':
        case 'TIMER':
        case 'TOGGLE_HISTORY':
            // Dispatched from setToken.
            interim = {...interim, ...action};
            return interim;

        case 'SET_PLAYER_COMPUTED':
            interim.accounts = state.accounts.map(account => {
                let acc = {...account};
                if (acc.id === action.account) {
                    acc.computed = action.isComputed
                }
                return acc;
            });
            return interim;

        case 'START_NEW_GAME':
            let {loggedIn, gameMode} = state;
            unchanged = {
                loggedIn: loggedIn,
                gameMode: (gameMode === 'train') ? 'train' : 'play'
            };
            return {...initialState, ...unchanged};

        case 'START_SELF_TRAIN':
            unchanged = {
                loggedIn: action.loggedIn,
                gameMode: action.gameMode,
                trainMode: action.trainMode,
                accounts: initialState.accounts.map(account => {
                    let acc = {...account};
                    acc.computed = true;
                    return acc;
                })
            };
            return {...initialState, ...unchanged};

        case 'MESSAGE':
            interim.message = {
                level: action.level,
                content: action.content
            };
            return interim;

        case 'MESSAGE_REMOVE':
            interim.message = {};
            return interim;

        default:
            return state;
    }
};

export default reducer;
