import React, { Component } from 'react';
import { connect } from 'react-redux';
import HistItem from './HistItem/HistItem';

import './HistoryTable.scss';

class HistoryTable extends Component {

    render = () => {
        let classes = ['History'];
        if (this.props.showHist) {
            classes.push('active');
        }
        let history = this.props.history.map((item, i) => {
            return <HistItem
                key={i}
                clicked={() => this.props.resetHistItem()} {...item} />
        });


        return <div className={classes.join(' ')}>
            <ul className="HistoryTable">
                { history }
            </ul>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        showHist: state.showHist,
        history: state.history
    }
};

const mapDispatchToProps = dispatch => {
    return {
        resetHistItem: () => {
            return dispatch({type: 'POSS_CELL_MOVE', attr: 'blool'})
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTable);