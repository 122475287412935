import React from 'react';

import './Overlay.scss'

const Overlay = props => {
    return (props.children) ? (
        <div className="Overlay" onClick={props.clicked}>
            <div className="close" onClick={props.close} />
            <div className="container">
                {props.children}
            </div>
        </div>
    ) : '';
};

export default Overlay;