import isEmpty from './isEmpty'

/**
 * Negation of isEmpty.
 *
 * @param normDispo string
 *   A complete game normDispo.
 *   I.e. 0000012020112...21212
 * @param needles array
 *   Array of integer positions to check.
 *   [23, 34, ... {0-63}]
 * @returns {boolean}
 *
 */
const notEmpty = (normDispo, needles) => {
    return !isEmpty(normDispo, needles)
};

export default notEmpty;