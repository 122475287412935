import React, { Component } from 'react';
import Overlay from './Overlay/Overlay';
import Imprint from './Contents/Imprint';
import PrivacyPolicy from './Contents/PrivacyPolicy';
import About from './Contents/About';
import Auth from "./Contents/Auth";

import './MainMenu.scss';
import othello3x from '../../img/Othello@3x.jpg';
import othello2x from '../../img/Othello@2x.jpg';
import othello1x from '../../img/Othello@1x.jpg';
import ReactGA from "react-ga";

class MainMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {content: false};
    }

    setContent(content, e) {
        e.preventDefault();
        ReactGA.event({
          category: 'User',
          action: 'Opens page: ' + e.target.textContent
        });
        this.setState({content: content});
    }

    render() {
        return <nav role="navigation" className="MainMenu">
            <label className="MainMenuToggle icon-menu" htmlFor="MainMenuCheckbox" id="MainMenuToggle" />
            <input type="checkbox" id="MainMenuCheckbox" style={{display: 'none'}}/>
            <div className="MainMenuWrap">
                <picture>
                    <source srcSet={othello3x} media="all and (min-width: 1240px)" type="image/jpeg"/>
                    <source srcSet={othello2x} media="all and (min-width: 813px) " type="image/jpeg" />
                    <source srcSet={othello1x} media="all and (max-width: 812px)" type="image/jpeg" />
                    <img src={othello1x} alt="Ai Othello" typeof="foaf:Image" />
                </picture>
                <h1>Ai Othello</h1>
                <ul className="menu">
                    <li><a href="?ov=about" onClick={(e) => this.setContent(<About />, e)}>About</a></li>
                    <li><a href="?ov=imprint" onClick={(e) => this.setContent(<Imprint />, e)}>Imprint</a></li>
                    <li><a href="?ov=privacy_policy" onClick={(e) => this.setContent(<PrivacyPolicy />, e)}>Privacy Policy</a></li>
                    <li><a href="?ov=admin" onClick={(e) => this.setContent(<Auth authSuccess={() => this.setState({content: false})} />, e)}>Admin</a></li>
                </ul>
              <ul className="social">
                <li><a href="https://www.facebook.com/sharer/sharer.php?u=https://ai-othello.com/" className="icon-facebook">Facebook</a></li>
                <li><a href="https://twitter.com/home?status=https://ai-othello.com/ Ai Othello - online game - Reversi" className="icon-twitter">Twitter</a></li>
              </ul>
                <div className="copyright">&copy; Joachim Feltkamp 2019</div>
            </div>
            <Overlay close={() => {
                ReactGA.event({
                    category: 'User',
                    action: 'Closes page'
                });
                this.setState({content: false})}
            } clicked={() => {
                if (document.getElementById('MainMenuCheckbox').checked) {
                    document.getElementById('MainMenuToggle').click()}
                }
            }>
                {this.state.content}
            </Overlay>
        </nav>;
    }
}

export default MainMenu;
