import React from 'react';
import ReactGA from 'react-ga';
import './App.scss';

import Board from './components/Board/Board';
import Message from './components/Message/Message';
import Accounts from './components/Accounts/Accounts';
import Controls from './components/Controls/Controls';
import FileLoader from "./components/devTools/FileLoader";
import TipsTable from "./components/devTools/TipsTable";
import MainMenu from "./components/MainMenu/MainMenu";
import HistoryTable from "./components/HistoryTable/HistoryTable";




function App() {
    ReactGA.initialize('UA-147348597-1');
    ReactGA.pageview('/home');

    return (
        <div className="App">
            <header className="AppHeader">
                <Controls />
                <MainMenu />
            </header>
            <div className="MainSection">
              <div className="Game">
                  <Accounts/>
                  <Message />
                  <Board />
              </div>
              <HistoryTable/>
            </div>
            <TipsTable />
            <FileLoader />
        </div>
    );
}

export default App;
