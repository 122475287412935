import React, { Component } from 'react';
import Cell from './Cell/Cell';
import { connect } from 'react-redux';
import Ruler from './Ruler/Ruler';
import Win from './Win/Win'
import { boardUpdated, setToken } from '../../game/game';

import './Board.scss';

class Board extends Component {

    componentDidUpdate(prevProps) {
        // When ID has changed next move must be initialized.
        const newId = (this.props.currentAccount.id !== prevProps.currentAccount.id);
        // When other account is blocked, the ID wouldn't change, but the status.
        const newStatus = (this.props.currentAccount.status !== prevProps.currentAccount.status);
        // Current account is computed.
        const currAccountComputed = (this.props.currentAccount.computed && this.props.currentAccount.id === this.props.toMove);
        // Self-playing mode always goes through.
        const bothAccComputed = this.props.accounts.every(acc => acc.computed);
        // Tips are calculated in second rotation after img is set and must go through.
        const showTips = this.props.showTips && (prevProps.showTips !== this.props.showTips);

        if (newId || newStatus || bothAccComputed || currAccountComputed || showTips) {
            boardUpdated();
        }
    }

    /**
     * Set color field ist occupied by.
     *
     * @param x number
     *   X-Position param.
     * @param y number
     *   Y-Position param.
     * @param classes array
     *   Cell classes array.
     */
    getCellColor = (x, y, classes) => {
        if (this.props.disposition[y][x] === 1) {
            classes.push('White');
        } else if (this.props.disposition[y][x] === 2) {
            classes.push('Black');
        }
    };

    /**
     * Adds marker className for possible moves of current player.
     *
     * @param classes array
     */
    getPossMoveColor = (classes) => {
        if (this.props.toMove === 1) {
            classes.push('PossibleWhite');
        } else if (this.props.toMove === 2) {
            classes.push('PossibleBlack');
        }
    };

    render() {
        let cells = [];

        for(let y = 0; y < 8; y++) {
            for(let x = 0; x < 8; x++) {
                const index = y * 8 + x;
                let cellProps = {
                    key: 'key' + x + y,
                    classes: [],
                    line: y,
                    row: x,
                };
                this.getCellColor(x, y, cellProps.classes);
                if(this.props.possibleMoves.indexOf(index) !== -1) {
                    this.getPossMoveColor(cellProps.classes);
                    // Check if user action is expected.
                    if (
                        !this.props.currentAccount.computed
                        && !this.props.currentAccount.disabled
                    ) {
                        cellProps.clicked =
                            () => setToken(x, y);
                    }
                }
                let tip = '';

                // Place tips on board if dev mode is enabled.
                if(this.props.showTips && this.props.tips.hasOwnProperty(index)) {
                    tip = (<span className="tip">{this.props.tips[index]}</span>);
                }
                cells.push(<Cell {...cellProps}>{tip}</Cell>);
            }
        }

        let rulers = [];
        const align = ['top','bottom','left','right'];
        for(let i = 0; i < 4; i++) {
            rulers.push(<Ruler key={i} classes={[align[i]]} scale={(i<2) ? "abcdefgh" : "12345678"} />)
        }

        const win = (this.props.winner) ? <Win/> : [];

        return <div className="Plate">
          <div className="Board">{cells}</div>
          {rulers}{win}
        </div>
    }
}

const mapStateToProps = state => {
    let currentAccount = (state.toMove !== 0)
        ? state.accounts[state.toMove - 1]
        : false;

    return {
        accounts: state.accounts,
        currentAccount: currentAccount,
        toMove: state.toMove,
        disposition: state.disposition,
        possibleMoves: state.possibleMoves,
        winner: state.winner,
        showTips: state.devMode && ( !currentAccount.computed
            || (state.gameMode === 'show' && state.showMode === 'step')),
        tips: state.tips
    }
};

export default connect(mapStateToProps)(Board);
