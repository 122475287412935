import React, {Component} from 'react';
import {connect} from 'react-redux';
import './Accounts.scss'

import Playergroup from './PlayerGroup/PlayerGroup';

class Accounts extends Component {



    render = () => {
        const controlls = this.props.accounts.map(item => (<Playergroup
            key={item.id}
            clicked={() => this.props.setAccountComputed(item.id, !item.computed)}
            {...item}/>));
        return <div className="Accounts"><div className="Clock">{controlls}</div></div>
    }
}

const mapStateToProps = state => {
    return {
        accounts: state.accounts,
        status: state.status,
    }
};

const mapDispatchToProps = dispatch => {

    return {
        setAccountComputed: (account, isComputed) => {
            return dispatch({type: 'SET_PLAYER_COMPUTED', account: account, isComputed: isComputed})
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
