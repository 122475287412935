import React from 'react';


const Imprint = () => {
    return (
        <div className="Imprint">
            <h1>Imprint/Impressum</h1>
            <p>Joachim Feltkamp<br />
            Wischhofsweg 4<br />22523 Hamburg</p>
            <p>Telefon: +4904035586110<br />
            E-Mail: <a href="mailto:j.feltkamp@gmail.com">j.feltkamp@gmail.com</a><br />
          </p>
          <h2>Verantwortlich für den Inhalt</h2>
          <p>(gem. § 55 Abs. 2 RStV):<br />
            Joachim Feltkamp, Wischhofsweg 4, 22523 Hamburg</p>

        </div>
    );
};

export default Imprint;