import React from 'react';


const About = () => {
  return (
    <div className="About">
      <h1>Über Ai-Othello</h1>

      <p>Dieses Othello-Spiel ist lernfähig. Ai steht für „artificial intelligence“. Es lernt aus jeder Party, die es
        gespielt hat. Wenngleich es mit ca. 1600 Meisterspielen gefüttert wurde, heißt das nicht, dass es irgendeinen
        dieser Meister schlagen könnte. Davon sind wir noch weit entfernt. - Dennoch macht uns das vorliegende Ergebnis
        sehr stolz.</p>

      <h2>Worin besteht das Problem?</h2>
      <p>Man merkt es dem Spiel nicht an, aber es gibt sehr viele Variationen. Jeder kennt die Geschichte von dem
        Schachbrett und den Reiskörnern. Das Othello-Spielbrett hat ebensoviele Felder wie ein Schachspiel. Es gibt
        theoretisch 10^28 verschiedene Spielzustände, die zusätzlich je mit verschiedenen Zugkombinationen erreicht
        werden können. Würde man den Speicherplatz pro Zustand auf 100 Byte reduzieren können, würde der Speicherbedarf
        ca. bei 1 Mio. YottaByte liegen. - Was? YottaByte kennen sie nicht? ;-). Eine Eins mit 24 Nullen. Der Größte
        Datenspeicher der Welt hat 1 ExaByte, also eine Eins mit 15 Nullen. Davon bräuchten wir dann eine Milliarden
        Stück.</p>

      <p>Man kann also nicht einfach alle Daten speichern und bei Bedarf abrufen, sondern muss sehr viel trickreicher
        vorgehen. Man sieht auch, dass 1600 Othello-Partien eine verschwindend winzige Menge als Erfahrungsbasis
        darstellen.</p>

      <p>Bei der hier vorgestellten Lösung kann man ganz deutliche Lernfortschritte feststellen und das gespeicherte
        Datenvolumen liegt weit unter 100MB.</p>

      <h2>Worin besteht der Lösungsansatz?</h2>
      <p>Statt das ganze Spielbrett für jeden Zug auszuwerten, versuchen wir, uns nur auf entscheidende Szenen zu
        konzentrieren. Wer Othello kennt, weiß, wer die Ecken kontrolliert, gewinnt das Spiel. Also konzentrieren wir
        uns z.B. auf die Szenen, die sich bei der Eroberung der Ecken abspielen. </p>

      <h2>Was sind Szenen?</h2>
      <p>Eine Szene ist eine kleine Region z.B. um eine Ecke des Spielfeldes herum, von max. 10 Feldern. Nur diese Szene
        wird genau beobachtet. Jedes Mal, wenn ein Spielstein in eine solche Szene hinein gesetzt wird, und die gesetzte
        Farbe am Ende gewinnt, wird dieser Zug positiv bewertet, verliert die Farbe, wird er negativ bewertet. - Sehr
        einfach. </p>

      <p>Die Szenarien sind so geformt, dass wichtige Entwicklungen auf dem Spielfeld, z.B. eine Falle, die dazu dient,
        eine Ecke zu gewinnen, möglichst innerhalb eines Szenarium abgebildet werden kann. Nur dann ist mit
        signifikanten Ergebnissen zu rechnen. Die Szenarien selbst können nach ausreichender Spielerfahrung evaluiert
        werden. Liefern sie im Schnitt nur 50/50-Ergebnisse, dann müssen sie verbessert, gelöscht oder durch andere
        ersetzt werden. - Darin besteht die Arbeit, um das Spiel weiter zu verbessern. Zur Zeit werden nur 10
        verschiedene Szenarien verwendet.</p>

      <p>Die verwendeten Szenarien haben in der Regel eine 16-fach Symmetrie, d.h. Spielzüge, die in der Ecke oben links
        gelernt wurden, können in allen anderen Ecken angewendet werden. Das Eck-Szenarium ist zusätzlich durch
        vertauschen der X- und Y-Achse symmetrisch. Und natürlich können Schwarz und Weiß gegeneinander getauscht
        werden. 4 x 2 x 2 = 16</p>

      <p>Der Vorteil, der darin liegt, nur kleine Szenarien zu beobachten und zu evaluieren, statt des ganzen
        Spielfeldes, ist der Speicherbedarf. Wenn ich nur 10 Felder beobachte, kommt man mit allen Variationen und
        Kombinationen auf ein Speichermaß von 5MB. Bei 10 Szenarien kommt man insgesamt auf ca. 50MB.</p>

      <p>Der Nachteil dieser Vorgehensweise ist, dass Abhängigkeiten verloren gehen; d.h. dass ein Spielzug nur dann gut
        ist, wenn bestimmte Voraussetzungen außerhalb meiner Szene gegeben sind, die nicht beobachtet und gespeichert
        werden. </p>

      <strong>Dieser Nachteil kann auf zweierlei Weise abgefangen werden:</strong>
      <ol>
        <li>indem zusätzlich Bedingungen formuliert werden können, unter denen ein Szenarium ausgewertet werden darf,
          z.B. nur dann, wenn die Ecken noch unbesetzt sind, oder durch Spielsteine meiner Farbe besetzt sind. Die
          Bedingungen können sich auf Zellen oder Reihen des ganzen Spielfeldes beziehen.
        </li>
        <li>Zweitens können Szenarien sich gegenseitig ergänzen. Wenn zwei oder mehr überlappende Szenarien Ergebnisse
          zu demselben Zug liefern, dann kann man davon ausgehen, dass ihre Summe ein besseres Ergebnis liefert, als ein
          Szenarium alleine. Es wäre denkbar, statt 10 Szenarien 100 oder sogar 1000 zu beobachten. Das wären dann 500
          MB bei 100 Szenen oder 5 GB bei 1000, was alles noch machbar ist. Allerdings ginge das zu lasten der
          Spielperformance, denn statt 10 müssen dann 1000 Szenarien während der Laufzeit eines Spielzuges summiert
          werden, was insbesondere beim Master-Game-Loading ein Problem darstellt. Statt 6 Stunden Ladezeit, wären es
          dann 600 Stunden = 24 Tage.
        </li>
      </ol>

      <h2>Master-Game-Loading</h2>
      <p>Wenn Szenarien verändert oder neu eingerichtet worden sind, muß die Spieldatenbank neu geladen werden. Dafür
        besitzt das Spiel einen Entwicklermodus, in dem 1500 Weltmeisterschaftsspiele (2003-2006) automatisiert
        durchgespielt werden. Danach spielt der Computer eben so oft gegen sich selbst. Dieser Prozess läuft mehrer
        Stunden. Anschließend sollte die hoffentlich verbesserte Spielstärke wiederhergestellt sein.</p>


      <h2>Dependencies</h2>
      <p>Das Spiel ist auf der Basis von React und Redux gebaut. Die Datenbank, in der Spielerfahrungen gespeichert
        werden, basiert auf Symfony/Doctrine.</p>

    </div>
  );
};

export default About;