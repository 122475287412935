/**
 * Scenario Fork
 *
 *   x 1 3 . . . . .
 *   0 x 5 . . . . .
 *   2 4 6 . . . . .
 *   . . . 7 . . . .
 *   . . . . 8 . . .
 *   . . . . . 9 . .
 *   . . . . . . . .
 *   . . . . . . . .
 *
 */

export default class ScenarioFork {
    name = 'fork';
    weight = 5;
    schemes;

    constructor() {
        this.schemes = [
            {
                a: [8,1,16,2,17,10,18,27,36,45],
                b: [1,8,2,16,10,17,18,27,36,45]
            },
            {
                a: [6,15,5,23,13,22,21,28,35,42],
                b: [15,6,23,5,22,13,21,28,35,42]
            },
            {
                a: [55,62,47,61,46,53,45,36,27,18],
                b: [62,55,61,47,53,46,45,36,27,18]
            },
            {
                a: [57,48,58,40,50,41,42,35,28,21],
                b: [48,57,40,58,41,50,42,35,28,21]
            },
        ];
        this.conditions = [
            {
                a: { isEmpty: [0,9] },
                b: { isEmpty: [0,9] }
            },
            {
                a: { isEmpty: [7,14] },
                b: { isEmpty: [7,14] }
            },
            {
                a: { isEmpty: [63,54] },
                b: { isEmpty: [63.54] }
            },
            {
                a: { isEmpty: [56,49] },
                b: { isEmpty: [56,49] }
            }
        ]
    }

}