import SolutionFinder from './SolutionFinder/SolutionFinder'
import GameLoader from './GameLoader';
import store from '../store/store';
import { getLog } from "./game_history";

/**
 * Trigger of board to init next move.
 */
const boardUpdated = () => {
    const state = store.getState();
    let timer, gameLoader;
    switch(state.gameMode) {
        case 'play':
            // Check if next move is to be computed.
            let currentAccount = state.accounts[state.toMove -1];
            if (currentAccount && currentAccount.computed) {
                // Trigger computed move.
                const solFinder = new SolutionFinder();
                solFinder.initSolution(true);
            } else if (state.devMode) {
                // Get computed tips.
                const solFinder = new SolutionFinder();
                solFinder.initSolution(false);
            }
            break;
        case 'train':
            if(state.trainMode === 'masters') {
                // If we are in train mode, init next move with timeout.
                gameLoader = new GameLoader();
                timer = setTimeout(
                    () => gameLoader.initMove(),
                    60
                );
                store.dispatch({type: 'TIMER', timer: timer});
            } else if (state.trainMode === 'self') {
                // Trigger computed move.
                const solFinder = new SolutionFinder();
                solFinder.initSolution(true);
            } else if (state.trainMode === 'stopped') {
                clearTimeout(state.timer);
            }
            break;
        case 'show':
            if (state.showMode === 'run') {
                gameLoader = new GameLoader();
                timer = setTimeout(
                    () => gameLoader.initMove(),
                    1000
                );
                store.dispatch({type: 'TIMER', timer: timer});
            } else if (state.showMode === 'step' && state.devMode) {
                // Get computed tips.
                const solFinder = new SolutionFinder();
                solFinder.initSolution(false);
            }
            break;
        default:

    }

};

/**
 * Recursive function to check a line(dx,dy), if a move (x,y) turns the color.
 *
 * @param dy integer(-1, 0, 1)
 *   Y-direction to search.
 * @param dx integer(-1, 0, 1)
 *   X-direction to search.
 * @param x integer
 *   X coordinate of checked cell.
 * @param y integer
 *   Y coordinate of checked cell.
 * @param bracket boolean
 *   If a closing bracket of mine color is searched or included tokens of opponent color.
 * @param disposition array
 *   Current game state.
 * @param toMove integer
 *   Gives the color what is to move.
 *
 * @returns {boolean, function}
 *   Checks a line if it can be closed and turned by img of color to move.
 */
const checkLine = (dy, dx, y, x, bracket, disposition, toMove) => {
    if (dx === 0 && dy === 0) return false;

    // Coordinates of neighbor field.
    const lineY = y + dy;
    const lineX = x + dx;

    // Check if neighbor field is in the board or outside.
    if ((0 <= lineY && lineY <= 7) && (0 <= lineX && lineX <= 7)) {
        if (disposition[lineY][lineX] === 0) return false;
        // Check if neighbor field opens a line.
        if (toMove !== disposition[lineY][lineX]) {
            // Line is opened. Now we check, if line has closing bracket.
            return checkLine(dy, dx, lineY, lineX, true, disposition, toMove);
        } else {
            // SUCCESS with simplified check if a bracket is searched.
            return bracket;
        }
    } else {
        // Neighbor field not exist or is outside the board.
        return false;
    }
};

/**
 * Check a cell if the disposition allows to set a img/Spielstein.
 *
 * @param x integer
 *   X coordinate of checked cell.
 * @param y integer
 *   Y coordinate of checked cell.
 * @param disposition array
 *   Current game state.
 * @param toMove integer
 *   Gives the color what is to move.
 *
 * @returns {boolean}
 *   If it is possible to set img.
 */
const isPossibleMove = (x, y, disposition, toMove) => {
    // Field is already occupied.
    if (disposition[y][x] !== 0) return false;

    // Direction coordination.
    //
    //  \ | /  (-1, -1) (0, -1) (1, -1)
    //  - P -  (-1,  0) (0,  0) (1,  0)
    //  / | \  (-1,  1) (0,  1) (1,  1)
    //
    for(let dy = -1; dy <= 1; dy++) {
        for(let dx = -1; dx <= 1; dx++) {
            let possible = checkLine(dy, dx, y, x, false, disposition, toMove);
            if (possible) {
                return true;
            }
        }
    }
};

/**
 * Gives a complete overview about possible moves.
 *
 * @param disposition
 *   Game disposition as modelled in initial state. array of 8 arrays
 *   with field state (1 || 2 for b/w img, 0 for empty field.)
 * @param toMove
 *   Account ID of account to move.
 *
 * @returns {Array}
 *   Array with indexes in stringified disposition.
 *   i.e. [12, 20, 27, 61]
 */
const getAllPossibleMoves = (disposition, toMove) => {
    let result = [];
    for (let y = 0; y < 8; y++) {
        for (let x = 0; x < 8; x++) {
            if( isPossibleMove(x, y, disposition, toMove)) {
                result.push(y * 8 + x);
            }
        }
    }
    return result;
};

/**
 * Recursive function to check a line(dx,dy), if a move (x,y) turns the color.
 *
 * @param dy integer(-1, 0, 1)
 *   Y-direction to search.
 * @param dx integer(-1, 0, 1)
 *   X-direction to search.
 * @param x integer
 *   X coordinate of checked cell.
 * @param y integer
 *   Y coordinate of checked cell.
 * @param disposition array
 *   Current game state.
 * @param toMove integer
 *   Gives the color what is to move.
 * @returns {boolean, function}
 */
const turnLine = (dy, dx, y, x, disposition, toMove) => {
    const my = y + dy;
    const mx = x + dx;
    if(disposition[my][mx] !== toMove) {
        disposition[my][mx] = toMove;
        turnLine(dy, dx, my, mx, disposition, toMove);
    }
    return disposition;
};

/**
 * Command to turn all lines after move (x, y).
 *
 * @param x integer(0..7)
 *   X-coordinate.
 * @param y integer(0..7)
 *   Y-coordinate.
 * @param disposition array
 *   The current disposition.
 * @param toMove
 *   Color to move.
 * @returns {{disposition: (boolean|Function|*), toMove: number}}
 */
const turnLines = (x, y, disposition, toMove) => {
    let retDisposition = disposition;
    for(let dy = -1; dy <= 1; dy++) {
        for(let dx = -1; dx <= 1; dx++) {
            if (checkLine(dy, dx, y, x, false, retDisposition, toMove)) {
                retDisposition = turnLine(dy, dx, y, x, retDisposition, toMove);
            }
        }
    }
    return retDisposition;
};

/**
 * Get the numbers of tokens from game disposition and update account
 * objects given in params.
 *
 * @param disposition
 *   Game disposition as modelled in initial state. array of 8 arrays
 *   with field state (1 || 2 for b/w img, 0 for empty field.)
 * @param accounts
 *   Accounts as modelled in initial state.
 *
 * @returns array
 *   Array with account objects.
 */
const statusCount = (disposition, accounts) => {
    let string = disposition.join(',');
    return accounts.map(account => {
        let uAcc = {...account};
        const regEx = new RegExp(account.id, 'g');
        uAcc.status = (string.match(regEx) || []).length;
        return uAcc
    });
};

/**
 * When game is over get the winner.
 *
 * @param accounts
 *   Accounts as modelled in initial state.
 *
 * @returns {number}
 *   Account id (1 || 2) of the winning account.
 *   Returns 3 on a draw.
 */
const getWinner = (accounts) => {
    if(accounts[0].status > accounts[1].status) {
        return 1;
    } else if(accounts[0].status < accounts[1].status) {
        return 2;
    } else {
        return 3;
    }
};

/**
 * Set img to given position (x, y) by dispatching PLAYER_MOVES.
 *
 * @param x integer(0..7)
 *   X-coordinate.
 * @param y integer(0..7)
 *   Y-coordinate.
 *
 * @dispatch {PLAYER_MOVES|MESSAGE}
 */
const setToken = (x, y) => {
    const state = store.getState();
    const accounts = [...state.accounts].map(account => {return {...account}});

    if (isPossibleMove(x, y, state.disposition, state.toMove)) {
        let newDisposition = state.disposition.map((el) => [...el]);
        newDisposition[y][x] = state.toMove;
        newDisposition = turnLines(x, y, newDisposition, state.toMove);

        // While next possible moves unknown, set other account as upcoming.
        let whileToMove = (state.toMove === 1) ? 2 : 1;

        // Update status display.
        const newAccounts = statusCount(newDisposition, accounts);

        // Calc possible moves.
        let possibleMoves = getAllPossibleMoves(newDisposition, whileToMove);

        // Block upcoming account, if no possible moves.
        let newToMove;
        let blocked = [];
        let newGameOver = false;
        let winner = 0;
        if (possibleMoves.length === 0) {
            blocked.push(whileToMove);
            newToMove = (whileToMove === 1) ? 2 : 1;
            possibleMoves = getAllPossibleMoves(newDisposition, newToMove);

            if(possibleMoves.length === 0) {
                blocked.push(newToMove);
            }

            // End game if other account was blocked before.
            // Game ends when both accounts are blocked.
            if (blocked.length > 1) {
                newToMove = 0;
                newGameOver = true;
                winner = getWinner(newAccounts);
            }
        } else {
            // Reset blocked. Other account may have moves in next round.
            newToMove = whileToMove;
        }

        // Update history.
        let history = [...state.history];
        const log = getLog(x, y, state.disposition, state.toMove);
        history.push(log);

        // Update game position.
        let loadedGame = { ...state.loadedGame };
        if(['train', 'show'].indexOf(state.gameMode) !== -1) {
            loadedGame.position++;
        }

        store.dispatch({
            type: 'PLAYER_MOVES',
            accounts: newAccounts,
            blocked: blocked,
            disposition: newDisposition,
            gameOver: newGameOver,
            history: history,
            possibleMoves: possibleMoves,
            prevDisposition: state.disposition,
            tips: {},
            toMove: newToMove,
            loadedGame: loadedGame,
            winner: winner,
        });
    } else {
        let moveLabel = "ABCDEFGH".charAt(x) + (y+1);
        store.dispatch({
            type: 'MESSAGE',
            content: 'Try to do impossible move %%.'.replace('%%', moveLabel),
            level: 'error',
        });
    }
};

export {
    boardUpdated,
    isPossibleMove,
    getAllPossibleMoves,
    statusCount,
    setToken,
    getWinner
};