/**
 * Scenario Birds
 *
 * Beginning scenario with baseline and edge keys empty.
 *
 *   x x 5 7 . . . .
 *   x x 6 8 . . . .
 *   0 1 . 9 . . . .
 *   2 3 4 . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *
 */

export default class ScenarioBirds {
    name = 'birds';
    weight = 5;
    schemes;
    conditions;

    constructor() {
        this.schemes = [
            {
                a: [16,17,24,25,26, 2,10, 3,11,19],
                b: [ 2,10, 3,11,19,16,17,24,25,26]
            },
            {
                a: [ 5,13, 4,12,20,23,22,31,30,29],
                b: [23,22,31,30,29, 5,13, 4,12,20]
            },
            {
                a: [47,46,39,38,37,61,53,60,52,44],
                b: [61,53,60,52,44,47,46,39,38,37]
            },
            {
                a: [58,50,59,51,43,40,41,32,33,34],
                b: [40,41,32,33,34,58,50,59,51,43]
            },
        ];
        this.conditions = [
            {
                a: { isEmpty: [ 0, 1, 8, 9] },
                b: { isEmpty: [ 0, 1, 8, 9] }
            },
            {
                a: { isEmpty: [ 6, 7,14,15] },
                b: { isEmpty: [ 6, 7,14,15] }
            },
            {
                a: { isEmpty: [54,55,62,63] },
                b: { isEmpty: [54,55,62,63] }
            },
            {
                a: { isEmpty: [48,49,56,57] },
                b: { isEmpty: [48,49,56,57] }
            },
        ]
    }

}