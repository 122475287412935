import React, {Component} from "react";
import { connect } from 'react-redux';
import NewGame from "./NewGame/NewGame";
import Loader from "./Loader/Loader";
import Training from "./Training/Training";
import DevMode from "../devTools/DevMode";
import PrevBtn from "./Loader/PrevBtn";
import RunBtn from "./Loader/RunBtn";
import NextBtn from "./Loader/NextBtn";
import ShowHist from "./ShowHist/ShowHist";

import './Controlls.scss';

class Controls extends Component {

    render() {
        let trainCtrl = <Loader />;
        if (this.props.gameMode === 'show') {
            const mode = this.props.showMode;
            const moves = this.props.loadedGame.moves.length;
            const position = this.props.loadedGame.position;
            const first = moves && position <= 0;
            const last = moves && position >= (moves);
            trainCtrl = (
                <div className="BtnGroup">
                    <PrevBtn enabled={!first && mode === 'step'} />
                    <RunBtn />
                    <NextBtn enabled={!last && mode === 'step'} />
                </div>
            );
        }
        return (
            <div className="Controlls">
                <Training />
                <DevMode />
                {trainCtrl}
                <ShowHist />
                <NewGame />
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        gameMode: state.gameMode,
        showMode: state.showMode,
        loadedGame: state.loadedGame
    }
};


export default connect(mapStateToProps)(Controls)
