import React, {Component} from 'react'
import {connect} from 'react-redux'
import {boardUpdated} from "../../../game/game";

class RunBtn extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.showMode !== this.props.showMode
            && this.props.showMode === 'run'
        ) {
            boardUpdated();
        }
    }

    render() {

        // Set Play/Stop icon and mode.
        let classes = ['Run', 'Btn'];
        let showMode = 'run';
        if(this.props.showMode === showMode) {
            classes.push('icon-on');
            showMode = 'step';
        } else {
            classes.push('icon-off');
            clearTimeout(this.props.timer);
        }

        return <button
            className={classes.join(' ')}
            onClick={() => this.props.switchTrainMode(showMode)}/>

    }
}

const mapStateToProps = state => {
    return {
        showMode: state.showMode,
        loadedGame: state.loadedGame,
        timer: state.timer
    }
};

const mapDispatchToProps = dispatch => {
    return {
        switchTrainMode: (showMode) => {
            dispatch({
                type: 'SHOW_RUN_PAUSE',
                showMode: showMode,
                tips: {},
                tipsTable: []
            });
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RunBtn)