import isEmpty from './Conditions/isEmpty';
import notEmpty from './Conditions/notEmpty';
import isMine from './Conditions/isMine';
import isTheirs from './Conditions/isTheirs'

class ConditionManager {
    normDispo;
    conditionTypes;
    scenarios;

    constructor(normDispo, scenarios) {
        this.normDispo = normDispo;
        this.conditionTypes = {
            'isEmpty': (normDispo, needles) => isEmpty(normDispo, needles),
            'notEmpty': (normDispo, needles) => notEmpty(normDispo, needles),
            'isMine': (normDispo, needles) => isMine(normDispo, needles),
            'isTheirs': (normDispo, needles) => isTheirs(normDispo, needles)
        };
        this.scenarios = scenarios;
    }


    check(type, index, symmetry) {
        let scenario = this.getScenario(type);

        // Return TRUE if has no conditions.
        if(!scenario || scenario.conditions === 'undefined' || !scenario.conditions)
            return true;

        // Drill down to get scenario conditions.
        let scenarioConditions = {};
        if (typeof scenario.conditions[index] !== 'undefined') {
            if (typeof scenario.conditions[index][symmetry] !== 'undefined') {
                scenarioConditions = scenario.conditions[index][symmetry];
            }
        }

        // Exec Conditions.
        let checks = Object.keys(scenarioConditions)
            .map(checkId => {
                try {
                    return this.conditionTypes[checkId](
                        this.normDispo,
                        scenarioConditions[checkId]
                    )
                } catch(err) {
                    console.log(err.message);
                    return null;
                }
            });

        // Return false as result if one value is false.
        for (let i = 0; i < checks.length; i++) {
            if (checks[i] !== true) {
                return false;
            }
        }
        return true
    }

    /**
     * Return scenario to check or null if it misses.
     *
     * @param type
     * @returns {null}
     */
    getScenario(type) {
        return (typeof this.scenarios[type] !== 'undefined')
            ? this.scenarios[type]
            : null;
    }
}

export default ConditionManager;