import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Message.scss';

class Message extends Component {

    render() {
        let message = '';
        if(typeof this.props.message.content !== 'undefined') {
            let classes = ['Message'];
            classes.push(this.props.message.level);
            message = (<div className={classes.join(' ')}><span className="icon-alert" />{
                this.props.message.content
            }<span className="close" onClick={this.props.removeMassage}/></div>);
        }
        return message;
    }
}

const MapStateToProps = state => {
    return {
        message: state.message
    }
};

const MapDispatchToProps = dispatch => {
    return {
        removeMassage: () => dispatch({type: 'MESSAGE_REMOVE'})
    }
};

export default connect(MapStateToProps, MapDispatchToProps)(Message);