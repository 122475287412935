/**
 * Scenario Axt
 *
 *   0 2 5 . . . . .
 *   1 4 . . . . . .
 *   3 . 6 . . . . .
 *   . . . 7 . . . .
 *   . . . . 8 . . .
 *   . . . . . 9 . .
 *   . . . . . . . .
 *   . . . . . . . .
 *
 */

export default class ScenarioAxt {
    name = 'axt';
    weight = 5;
    schemes;

    constructor() {
        this.schemes = [
            {
                a: [ 0, 8, 1,16, 9, 2,18,27,36,45],
                b: [ 0, 1, 8, 2, 9,16,18,27,36,45]
            },
            {
                a: [ 7, 6,15, 5,14,23,21,28,35,42],
                b: [ 7,15, 6,23,14, 5,21,28,35,42]
            },
            {
                a: [63,55,62,47,54,61,45,36,27,18],
                b: [63,62,55,61,54,47,45,36,27,18]
            },
            {
                a: [56,57,48,58,49,40,42,35,28,21],
                b: [56,48,57,40,49,58,42,35,28,21]
            },
        ];
    }

}