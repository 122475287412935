/**
 * Schemes to organize symmetry of different dispositions.
 *
 * Used slug house symmetry to minify the numbers from the beginning of the game. All
 * eight schemes (4 schemes * 2 directions) were generated and smallest number is selected.
 *
 * Natural disposition is organized in lines. x/y: 0/0, 1/0 .. 6/7, 7/7 = [0..63]
 * Schemed disposition can be calculated with scheme number [0..3] and direction [+1, -1]
 *
 * natural = (y * 8) + x
 *
 * Calculation (nat -> schemed):
 * schemed = scheme[num][natural]
 *   or for other direction
 * schemed = scheme[num][63 - natural]
 *
 * Reverse calculation (schemed -> nat):
 * natural = scheme[num].indexOf(schemed)
 *   or for other direction
 * natural = 63 - scheme[num].indexOf(schemed)
 *
 * @type {number[][]}
 */
import { stringifyDisp, unStringifyDisp } from "./tools";
import { getAllPossibleMoves, statusCount } from './game'
import store from '../store/store';

const getLog = (x, y, disposition, toMove) => {
    const disp = stringifyDisp(disposition);
    const move = (y * 8) + x;
    return {
        move: move,
        state: disp,
        toMove: toMove,
    }
};

export {
    getLog
}

class History {
    history;
    accounts;
    gameMode;
    showMode;
    loadedGame;

    constructor() {
        const state = store.getState();
        this.history = [...state.history];
        this.accounts = [...state.accounts];
        this.gameMode = state.gameMode;
        this.showMode = state.showMode;
        this.loadedGame = {...state.loadedGame}
    }

    rewindHistory(step) {
        step = parseInt(step, 10);

        if(step > this.history.length-1) {
            store.dispatch({
                type: 'MESSAGE',
                level: 'warn',
                content: 'Not possible to rewind.'
            });
            return false;
        }
        let index = (step < 0)
            ? this.history.length + step
            : step;

        // Get the required history item to what we want to rewind.
        const histItem = this.history[index];
        const disposition = unStringifyDisp(histItem.state);

        if(['train','show'].indexOf(this.gameMode) !== -1) {
            this.loadedGame.position = this.loadedGame.moves.indexOf(histItem.move);
            this.showMode = 'step';
        }

        store.dispatch({
            type: 'REWIND_HISTORY',
            disposition: disposition,
            toMove: histItem.toMove,
            history: this.history.slice(0, index),
            possibleMoves: getAllPossibleMoves(disposition, histItem.toMove),
            accounts: statusCount(disposition, this.accounts),
            showMode: this.showMode,
            loadedGame: this.loadedGame,
            gameOver: false,
            winner: 0,
            message: {}
        })
    }
}

export default History;