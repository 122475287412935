import React, {Component} from 'react';
import {connect} from 'react-redux';

class DevMode extends Component {
    render() {
        const devModeBtn = (this.props.loggedIn)
            ? (<label className="form-element fe-checkbox">
                <input
                    onChange={() => this.props.switchDevMode(!this.props.devMode)}
                    id="devMode"
                    type="checkbox"
                    className="devMode"
                    checked={this.props.devMode}
                />
                <span className="icon-cog" />
            </label>)
            : null;
        return devModeBtn;
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.loggedIn,
        devMode: state.devMode
    }
};

const mapDispatchToProps = dispatch => {
    return {
        switchDevMode: (devMode) => dispatch({type: 'SWITCH_DEV_MODE', devMode: devMode})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DevMode);