import React, {Component} from 'react';
import {connect} from 'react-redux';
import database from '../../store/database'

class FileLoader extends Component {
    fileReader;

    constructor(props) {
        super(props);
        this.fileReader = new FileReader();
    }

    /**
     * Extract defined props from a game XML sub element.
     *
     * @param elements
     * @param props
     * @returns {Array}
     */
    extractElementData(elements, props) {
        const data = [];
        for (let lmn of elements) {
            let attr = {};
            for(let i=0; i < props.length; i++) {
                attr[props[i]] = lmn.getAttribute(props[i]);
            }
            data.push({
                props: attr,
                value: lmn.textContent
            });
        }
        return (data.length === 1) ? data[0] : data;
    }

    /**
     * Extract a games from a uploaded file.
     *
     * @param game
     */
    extractGameData(game) {
        const search = {
            event: { props: ['date', 'name']},
            result: { props: ['winner', 'type']},
            player: { props: ['color', 'name']},
            moves: { props: []}
        };
        let result = {};
        const keys = Object.keys(search);
        for (let i = 0; i< keys.length; i++) {
            const key = keys[i];
            const elements = game.getElementsByTagName(key);
            result[key] = this.extractElementData(elements, search[key].props)
        }
        return result;
    };

    render() {

        const handleFileRead = () => {
            const content = this.fileReader.result;
            
            let xmlDoc;
            if (window.DOMParser) {
                const parser = new DOMParser();
                xmlDoc = parser.parseFromString(content, "text/xml");
            } else if (typeof window.ActiveXObject !== 'undefined') {
                // Internet Explorer
                xmlDoc = new window.ActiveXObject("Microsoft.XMLDOM");
                xmlDoc.async = false;
                xmlDoc.loadXML(content);
            } else return false;

            const games = xmlDoc.getElementsByTagName("game");
            let collection = [];
            for(let i = 0; i < games.length; i++) {
                collection.push(this.extractGameData(games[i]));
            }

            if (collection.length) {
                const db = new database(collection);
                db.storeGames((data) => {
                    let msg = (data.statusText === 'OK')
                        ? {level: 'status', content: data.data.message}
                        : {level: 'error', content: data.data.message}

                    this.props.loadedGames(msg);
                    console.log(data)
                });
            }
        };


        const handleFile = (file) => {
            if (file.length) {
                this.fileReader.readAsText(file[0]);
                this.fileReader.onloadend = handleFileRead;
            }
        };

        return (this.props.devMode && this.props.loggedIn)
            ? (<form encType="multipart/form-data">
                <input
                    id="upload"
                    type="file"
                    accept="text/xml"
                    name="files[]"
                    onChange={event => handleFile(event.target.files)}
                />
            </form>) : '';
    }
}

const mapStateToProps = state => {
    return {
        devMode: state.devMode,
        loggedIn: state.loggedIn
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadedGames: (message) => dispatch({type: 'MESSAGE', ...message}),
    }
};



export default connect(mapStateToProps, mapDispatchToProps)(FileLoader);