import React from 'react';


const PrivacyPolicy = () => {
  return (
    <div className="PrivacyPolicy">
      <h1>Datenschutz</h1>

      <p>Wir informieren Sie nachfolgend gemäß den gesetzlichen Vorgaben des Datenschutzrechts (insb. gemäß BDSG n.F.
        und der europäischen Datenschutz-Grundverordnung ‚DS-GVO‘) über die Art, den Umfang und Zweck der Verarbeitung
        personenbezogener Daten durch unser Unternehmen. Diese Datenschutzerklärung gilt auch für unsere Websites und
        Sozial-Media-Profile. Bezüglich der Definition von Begriffen wie etwa „personenbezogene Daten“ oder
        „Verarbeitung“ verweisen wir auf Art. 4 DS-GVO.</p>

      <h2>Name und Kontaktdaten des / der Verantwortlichen</h2>
      <p>Unser/e Verantwortliche/r (nachfolgend „Verantwortlicher“) i.S.d. Art. 4 Zif. 7 DS-GVO ist:<br/>
      <br/>Joachim Feltkamp<br/>Wischhofsweg, 4<br/>Hamburg<br/>Registergericht: Hamburg<br/>E-Mail-Adresse:
      j.feltkamp@gmail.com</p>

      <h2>Datenarten, Zwecke der Verarbeitung und Kategorien betroffener Personen</h2>
      <p>Nachfolgend informieren wir Sie über Art, Umfang und Zweck der Erhebung, Verarbeitung und Nutzung
        personenbezogener Daten.</p>
      <h2>1. Arten der Daten, die wir verarbeiten</h2>
      <p>Spieldaten</p>

      <h2>2. Zwecke der Verarbeitung nach Art. 13 Abs. 1 c) DS-GVO</h2>
      <p>Website technisch und wirtschaftlich optimieren, in dem Sinne, dass mit den erhobenen Spieldaten die
        Spielstärke des angebotenen Spiels gesteigert werden soll.</p>
      <br/>
      <h2>3. Kategorien der betroffenen Personen nach Art. 13 Abs. 1 e) DS-GVO</h2>
      <p>Die betroffenen Personen werden zusammenfassend als „Nutzer“ bezeichnet.</p>

      <h2>Rechtsgrundlagen der Verarbeitung personenbezogener Daten</h2>
      <p>Nachfolgend Informieren wir Sie über die Rechtsgrundlagen der Verarbeitung personenbezogener Daten:</p>
      <ol>
        <li>Wenn wir Ihre Einwilligung für die Verarbeitung personenbezogenen Daten eingeholt haben, ist Art. 6 Abs. 1
          S. 1 lit. a) DS-GVO Rechtsgrundlage.
        </li>
        <li>Ist die Verarbeitung zur Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich, die auf Ihre Anfrage hin erfolgen, so ist Art. 6 Abs. 1 S. 1 lit. b) DS-GVO Rechtsgrundlage.
        </li>
        <li>Ist die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der wir unterliegen (z.B.
          gesetzliche Aufbewahrungspflichten), so ist Art. 6 Abs. 1 S. 1 lit. c) DS-GVO Rechtsgrundlage.
        </li>
        <li>Ist die Verarbeitung erforderlich, um lebenswichtige Interessen der betroffenen Person oder einer anderen
          natürlichen Person zu schützen, so ist Art. 6 Abs. 1 S. 1 lit. d) DS-GVO Rechtsgrundlage.
        </li>
        <li>Ist die Verarbeitung zur Wahrung unserer oder der berechtigten Interessen eines Dritten erforderlich und
          überwiegen diesbezüglich Ihre Interessen oder Grundrechte und Grundfreiheiten nicht, so ist Art. 6 Abs. 1 S. 1
          lit. f) DS-GVO Rechtsgrundlage.
        </li>
      </ol>
      <br/>
      <h2>Weitergabe personenbezogener Daten an Dritte und Auftragsverarbeiter</h2>
      <p>Ohne Ihre Einwilligung geben wir grundsätzlich keine Daten an Dritte weiter. Sollte dies doch der Fall sein,
        dann erfolgt die Weitergabe auf der Grundlage der zuvor genannten Rechtsgrundlagen z.B. bei der Weitergabe von
        Daten an Online-Paymentanbieter zur Vertragserfüllung oder aufgrund gerichtlicher Anordnung oder wegen einer
        gesetzlichen Verpflichtung zur Herausgabe der Daten zum Zwecke der Strafverfolgung, zur Gefahrenabwehr oder zur
        Durchsetzung der Rechte am geistigen Eigentum.<br/>
        Wir setzen zudem Auftragsverarbeiter (externe Dienstleister z.B. zum Webhosting unserer Websites und
        Datenbanken) zur Verarbeitung Ihrer Daten ein. Wenn im Rahmen einer Vereinbarung zur Auftragsverarbeitung an die
        Auftragsverarbeiter Daten weitergegeben werden, erfolgt dies immer nach Art. 28 DS-GVO. Wir wählen dabei unsere
        Auftragsverarbeiter sorgfältig aus, kontrollieren diese regelmäßig und haben uns ein Weisungsrecht hinsichtlich
        der Daten einräumen lassen. Zudem müssen die Auftragsverarbeiter geeignete technische und organisatorische
        Maßnahmen getroffen haben und die Datenschutzvorschriften gem. BDSG n.F. und DS-GVO einhalten</p>

      <br/>
      <h2>Datenübermittlung in Drittstaaten</h2>
      <p>Durch die Verabschiedung der europäischen Datenschutz-Grundverordnung (DS-GVO) wurde eine einheitliche
        Grundlage für den Datenschutz in Europa geschaffen. Ihre Daten werden daher vorwiegend durch Unternehmen
        verarbeitet, für die DS-GVO Anwendung findet. Sollte doch die Verarbeitung durch Dienste Dritter außerhalb der
        Europäischen Union oder des Europäischen Wirtschaftsraums stattfinden, so müssen diese die besonderen
        Voraussetzungen der Art. 44 ff. DS-GVO erfüllen. Das bedeutet, die Verarbeitung erfolgt aufgrund besonderer
        Garantien, wie etwa die von der EU-Kommission offiziell anerkannte Feststellung eines der EU entsprechenden
        Datenschutzniveaus oder der Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen, der so
        genannten „Standardvertragsklauseln“. Bei US-Unternehmen erfüllt die Unterwerfung unter das sog.
        „Privacy-Shield“, dem Datenschutzabkommen zwischen der EU und den USA, diese Voraussetzungen.</p>

      <br/>
      <h2>Löschung von Daten und Speicherdauer</h2>
      <p>Sofern nicht in dieser Datenschutzerklärung ausdrücklich angegeben, werden Ihre personenbezogen Daten gelöscht
        oder gesperrt, sobald der Zweck für die Speicherung entfällt, es sei denn deren weitere Aufbewahrung ist zu
        Beweiszwecken erforderlich oder dem stehen gesetzliche Aufbewahrungspflichten entgegenstehen. Darunter fallen
        etwa handelsrechtliche Aufbewahrungspflichten von Geschäftsbriefen nach § 257 Abs. 1 HGB (6 Jahre) sowie
        steuerrechtliche Aufbewahrungspflichten nach § 147 Abs. 1 AO von Belegen (10 Jahre). Wenn die vorgeschriebene
        Aufbewahrungsfrist abläuft, erfolgt eine Sperrung oder Löschung Ihrer Daten, es sei denn die Speicherung ist
        weiterhin für einen Vertragsabschluss oder zur Vertragserfüllung erforderlich.</p>

      <br/>
      <h2>Bestehen einer automatisierten Entscheidungsfindung</h2>
      <p>Wir setzen keine automatische Entscheidungsfindung oder ein Profiling ein.</p>

      <br/>
      <h2>Bereitstellung unserer Website und Erstellung von Logfiles</h2>
      <ol>
        <li>Wenn Sie unsere Webseite lediglich informatorisch nutzen (also keine Registrierung und auch keine
          anderweitige Übermittlung von Informationen), erheben wir nur die personenbezogenen Daten, die Ihr Browser an
          unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten:
          • IP-Adresse;<br/>
          • Internet-Service-Provider des Nutzers;<br/>
          • Datum und Uhrzeit des Abrufs;<br/>
          • Browsertyp;<br/>
          • Sprache und Browser-Version;<br/>
          • Inhalt des Abrufs;<br/>
          • Zeitzone;<br/>
          • Zugriffsstatus/HTTP-Statuscode;<br/>
          • Datenmenge;<br/>
          • Websites, von denen die Anforderung kommt;<br/>
          • Betriebssystem.<br/>
          Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten von Ihnen findet nicht
          statt.<br/><br/>
        </li>
        <li>Diese Daten dienen dem Zweck der nutzerfreundlichen, funktionsfähigen und sicheren Auslieferung unserer
          Website an Sie mit Funktionen und Inhalten sowie deren Optimierung und statistischen Auswertung.<br/><br/>
        </li>
        <li>Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der
          Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.<br/><br/></li>
        <li>Wir speichern aus Sicherheitsgründen diese Daten in Server-Logfiles für die Speicherdauer von Tagen. Nach
          Ablauf dieser Frist werden diese automatisch gelöscht, es sei denn wir benötigen deren Aufbewahrung zu
          Beweiszwecken bei Angriffen auf die Serverinfrastruktur oder anderen Rechtsverletzungen.<br/></li>
      </ol>
      <br/>
      <h2>Etracker</h2>

      <ol>
        <li>Wir haben auf unserer Website den Webanalysedienst „etracker“ (etracker GmbH, Erste Brunnenstraße 1, 20459
          Hamburg) implementiert, um die Nutzung unserer Website zu analysieren. Hierbei werden Cookies eingesetzt, die
          in Ihrem Browser gespeichert werden und womit ein pseudonymes Nutzungsprofil erstellt wird. Mit den Cookies
          können Sie beim erneuten Besuch unserer Website wiedererkannt werden. Etracker wird ohne Ihre Zustimmung diese
          Daten nicht nutzen, um Sie zu identifizieren oder um diese mit personenbezogenen Daten zusammenzuführen. Die
          erhobenen Daten werden dauerhaft gespeichert und pseudonymisiert analysiert auf Servern in
          Deutschland.<br/><br/></li>
        <li>Diese Daten werden zum Zwecke des Marketings, der Analyse und Optimierung unserer Website gesammelt und
          gespeichert.<br/><br/></li>
        <li>Wir haben mit etracker eine Vereinbarung zur Auftragsverarbeitung nach Art. 28 DS-GVO geschlossen.<br/><br/>
        </li>
        <li>Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der
          Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.<br/><br/></li>
        <li>Sie können der Datenerhebung und -speicherung jederzeit kostenlos mit Wirkung für die Zukunft widersprechen.
          Sie können der Installation von Cookies durch etracker auf verschiedene Arten widersprechen bzw. diese
          verhindern:<br/><br/>
          • Sie können die Cookies in Ihrem Browser durch die <strong>Einstellung “keine Cookies akzeptieren”</strong>
          unterbinden, was auch die Cookies von Drittanbietern beinhaltet;<br/><br/>
          • Sie können die Cookies von etracker über diesen Link deaktivieren:<br/><br/>
          <a href="http://www.etracker.de/privacy?et=XXXXXX" target="_blank"
             rel="noopener noreferrer">http://www.etracker.de/privacy?et=XXXXXX</a>. Dieses Cookie gilt nur für unsere
          Website und Ihren aktuellen Browser und hat nur solange Bestand bis Sie Ihre Cookies löschen. In dem Falle
          müssten Sie das Cookie erneut setzen.<br/>
          <br/>
        </li>
        <li>Weitere Informationen entnehmen Sie der Datenschutzerklärung von etracker unter: <a
          href="https://www.etracker.com/datenschutz" target="_blank"
          rel="noopener noreferrer">https://www.etracker.com/datenschutz</a>.<br/><br/></li>
      </ol>
      <br/>
      <h2>Social-Media-Plug-ins</h2>

      <ol>
        <li>Wir setzen auf unserer Webseite Social-Media-Plug-ins von sozialen Netzwerken ein. Dabei nutzen wir die
          sog. <strong>„Zwei-Klick-Lösung“-Shariff</strong> von c’t bzw. heise.de. Beim Abruf unserer Website werden
          dadurch <strong>keine personenbezogenen Daten</strong> an die Anbieter der Plug-ins übermittelt. Neben dem
          Logo bzw. der Marke des sozialen Netzwerks finden Sie einen Regler, mit dem Sie das Plug-in per Klick
          aktivieren können. Nach der Aktivierung erhält der Anbieter des sozialen Netzwerks die Information, dass Sie
          unsere Website aufgerufen haben und Ihre personenbezogenen Daten an den Anbieter des Plug-ins übermittelt und
          dort gespeichert werden. Hierbei handelt es sich um sog. Thirdparty Cookies. Bei einigen Anbietern wie
          Facebook und XING wird nach deren Angaben Ihre IP nach der Erhebung sofort anonymisiert.<br/><br/></li>
        <li>Die über den Nutzer erhobenen Daten speichert der Plug-in-Anbieter als Nutzungsprofile. Diese werden für
          Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website verwendet. Eine solche
          Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur Darstellung von bedarfsgerechter
          Werbung und um andere Nutzer des sozialen Netzwerks über die Aktivitäten des Nutzers auf unserer Website zu
          informieren. Dem Nutzer steht ein Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu, wobei man sich
          zur Ausübung dieses Rechts an den jeweiligen Plug-in-Anbieter wenden muss.<br/><br/></li>
        <li>Rechtsgrundlage für die Nutzung der Plug-ins ist unser berechtigtes Interesse an der Verbesserung und
          Optimierung unserer Website durch die Steigerung unserer Bekanntheit mittels sozialer Netzwerke sowie die
          Möglichkeit der Interaktion mit Ihnen und der Nutzer untereinander über soziale Netzwerke gemäß Art. 6 Abs. 1
          S.1 lit. f) DS-GVO.<br/><br/></li>
        <li>Auf die erhobenen Daten und Datenverarbeitungsvorgänge haben wir keinen Einfluss. Auch haben wir keine
          Kenntnis vom Umfang der Datenerhebung, vom Zweck der Verarbeitung und von den Speicherfristen. Auch zur
          Löschung der erhobenen Daten durch den Plug-in-Anbieter liegen uns keine Informationen vor.<br/><br/></li>
        <li>Wir verweisen hinsichtlich des Zwecks und Umfangs der Datenerhebung und Verarbeitung auf die jeweiligen
          Datenschutzerklärungen der sozialen Netzwerke. Zudem finden Sie dort auch Hinweise zu Ihren Rechten und
          Einstellungsmöglichkeiten zum Schutz Ihrer personenbezogenen Daten.<br/><br/></li>
      </ol>
      <h2>Facebook</h2>
      <ol>
        <li>Wir haben auf unserer Website Plug-ins vom sozialen Netzwerk Facebook.com (Firmensitz in der EU: Facebook
          Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) im Rahmen der sog.
          „Zwei-Klick-Lösung“ von Shariff integriert.Diese erkennen Sie am Facebook-Logo „f“ bzw. dem Zusatz „Like“,
          „Gefällt mir“ oder „Share“.<br/><br/></li>
        <li>Sobald Sie willentlich das Facebook-Plug-in aktivieren, wird hierbei eine Verbindung von Ihrem Browser zu
          den Servern von Facebook hergestellt. Dabei erhält Facebook die Information, einschließlich Ihrer IP, dass Sie
          unsere Website aufgerufen haben und überträgt diese Information an Server von Facebook in den USA, wo diese
          Information gespeichert wird. Wenn Sie bei Facebook in Ihren Account eingeloggt sind, kann Facebook diese
          Information Ihrem Account zuordnen. Bei Nutzung der Funktionen des Plug-ins, z.B. Betätigung des
          „Like“-Buttons, werden diese Informationen ebenfalls von Ihrem Browser an die Server von Facebook in den USA
          übertragen und dort gespeichert sowie in Ihrem Facebook-Profil und ggf. bei Ihren Freunden
          angezeigt.<br/><br/></li>
        <li>Zweck und Umfang der Datenerhebung sowie ihre weitere Verarbeitung und Nutzung der Daten durch Facebook
          sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre, können Sie den
          Datenschutzhinweisen von Facebook entnehmen: <a href="https://www.facebook.com/about/privacy/" target="_blank"
                                                          rel="noopener noreferrer">https://www.facebook.com/about/privacy/</a>.
          Datenerhebung beim „Gefällt mir“-Button: <a href="https://www.facebook.com/help/186325668085084"
                                                      target="_blank"
                                                      rel="noopener noreferrer">https://www.facebook.com/help/186325668085084</a>.
          Ihre Einstellungen hinsichtlich der Nutzung Ihrer Profildaten zu Werbezwecken bei Facebook können Sie hier
          verwalten und widersprechen: <a href="https://www.facebook.com/ads/preferences/" target="_blank"
                                          rel="noopener noreferrer">https://www.facebook.com/ads/preferences/</a>.<br/><br/>
        </li>
        <li>Wenn Sie sich bei Facebook vor dem Besuch unserer Website ausloggen und Ihre Cookies löschen, werden bei der
          Aktivierung des Plug-ins keine Daten über den Besuch unserer Website Ihrem Profil auf Facebook
          zugeordnet.<br/><br/></li>
        <li>Sie können auch das Laden des Facebook Plug-Ins durch sog. „Facebook Blocker“ verhindern, welche Sie als
          Add-on für Ihren Browser installieren können: Facebook Blocker für <a
            href="https://addons.mozilla.org/de/firefox/addon/facebook-blocker/" target="_blank"
            rel="noopener noreferrer">Firefox</a>, <a
            href="https://chrome.google.com/webstore/detail/facebookblocker/cohbmkonphbnkadgpcpofdpjiopbbhdl"
            target="_blank" rel="noopener noreferrer">Chrome</a> und <a
            href="https://addons.opera.com/de/extensions/details/facebook-blocker/?display=en" target="_blank"
            rel="noopener noreferrer">Opera</a> oder <a href="https://1blocker.com/" target="_blank"
                                                        rel="noopener noreferrer">1blocker</a> für Safari, iPad und
          iPhone.<br/><br/></li>
        <li>Facebook hat sich dem Privacy Shield unterworfen und stellt damit sicher, dass europäisches Datenschutzrecht
          eingehalten wird: <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank"
                               rel="noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>.<br/><br/>
        </li>
      </ol>
      <h2>Twitter</h2>
      <ol>
        <li>Wir haben auf unserer Website Plug-Ins des sozialen Netzwerks Twitter.com (Twitter Inc., 1355 Market St.,
          Suite 900, San Francisco, California 94103, USA) im Rahmen der sog. „Zwei-Klick-Lösung“ von Shariff
          integriert. Diese Plug-Ins erkennen Sie an dem Twitter-Logo mit weißem Vogel auf blauem Hintergrund. Eine
          Übersicht über Twitter-Buttons bzw. Tweets finden Sie unter: <a
            href="https://developer.twitter.com/en/docs/twitter-for-websites/overview" target="_blank"
            rel="noopener noreferrer">https://developer.twitter.com/en/docs/twitter-for-websites/overview</a>.<br/><br/>
        </li>
        <li>Wenn Sie in Ihren Twitter-Account eingeloggt sind, während Sie die Twitter-Plug-ins willentlich aktivieren,
          kann Twitter den Anruf unserer Website Ihrem Twitter-Profil zuordnen. Welche Daten an Twitter übermittelt
          werden, ist uns nicht bekannt.<br/><br/></li>
        <li>Wenn Sie die Datenübermittlung bei Aktivierung des Plug-ins an Twitter ausschließen möchten, dann loggen Sie
          sich vor dem Besuch unserer Website bei Twitter aus und löschen Ihre Cookies.<br/><br/></li>
        <li>Zweck und Umfang der Datenerhebung sowie ihre weitere Verarbeitung und Nutzung der Daten durch Twitter sowie
          Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre, können Sie den
          Datenschutzhinweisen von Twitter entnehmen: <a href="https://twitter.com/de/privacy" target="_blank"
                                                         rel="noopener noreferrer">https://twitter.com/de/privacy</a>.
          Widerspruch (Opt-Out): <a href="https://twitter.com/personalization" target="_blank"
                                    rel="noopener noreferrer">https://twitter.com/personalization</a>.<br/><br/></li>
        <li>Twitter hat sich dem Privacy Shield unterworfen und stellt damit sicher, dass europäisches Datenschutzrecht
          eingehalten wird: <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank"
                               rel="noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>.<br/><br/>
        </li>
      </ol>
      <br/>
      <h2>Rechte der betroffenen Person</h2>

      <ol>
        <li><strong>Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten<br/><br/>
          Soweit die Verarbeitung auf Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a), Art. 7 DS-GVO beruht, haben
          Sie das Recht, die Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der aufgrund der Einwilligung bis
          zum Widerruf erfolgten Verarbeitung wird dadurch nicht berührt.<br/><br/>
          Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung gemäß Art. 6 Abs. 1 S. 1
          lit. f) DS-GVO stützen, können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die
          Verarbeitung insbesondere nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils
          bei der nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei Ausübung eines solchen Widerspruchs
          bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt
          verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die
          Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen,
          aufgrund derer wir die Verarbeitung fortführen.<br/><br/>
          Sie können der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und Datenanalyse jederzeit
          widersprechen. Das Widerspruchsrecht können Sie kostenfrei ausüben. Über Ihren Werbewiderspruch können Sie uns
          unter folgenden Kontaktdaten informieren:<br/><br/>Joachim Feltkamp<br/>Wischhofsweg, 4<br/>Hamburg<br/>Registergericht:
          Hamburg<br/>E-Mail-Adresse: j.feltkamp@gmail.com<br/></strong>
          <br/></li>
        <li><strong>Recht auf Auskunft</strong><br/>
          Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten
          verarbeitet werden. Sofern dies der Fall ist, haben Sie ein Recht auf Auskunft über Ihre bei uns gespeicherten
          persönlichen Daten nach Art. 15 DS-GVO. Dies beinhaltet insbesondere die Auskunft über die
          Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen
          Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, die Herkunft ihrer Daten, sofern diese
          nicht direkt bei Ihnen erhoben wurden.<br/><br/></li>
        <li><strong>Recht auf Berichtigung</strong><br/>
          Sie haben ein Recht auf Berichtigung unrichtiger oder auf Vervollständigung richtiger Daten nach Art. 16
          DS-GVO.
          <br/><br/>
        </li>
        <li><strong>Recht auf Löschung</strong><br/>
          Sie haben ein Recht auf Löschung Ihrer bei uns gespeicherten Daten nach Art. 17 DS-GVO, es sei denn
          gesetzliche oder vertraglichen Aufbewahrungsfristen oder andere gesetzliche Pflichten bzw. Rechte zur weiteren
          Speicherung stehen dieser entgegen.
          <br/><br/>
        </li>
        <li><strong>Recht auf Einschränkung</strong><br/>
          Sie haben das Recht, eine Einschränkung bei der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, wenn
          eine der Voraussetzungen in Art. 18 Abs. 1 lit. a) bis d) DS-GVO erfüllt ist:<br/>
          • Wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem
          Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;<br/><br/>
          • die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen
          die Einschränkung der Nutzung der personenbezogenen Daten verlangen;<br/><br/>
          • der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie
          diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder<br/><br/>
          • wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DS-GVO eingelegt haben und noch nicht
          feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.<br/><br/>
        </li>
        <li><strong>Recht auf Datenübertragbarkeit</strong><br/>
          Sie haben ein Recht auf Datenübertragbarkeit nach Art. 20 DS-GVO, was bedeutet, dass Sie die bei uns über Sie
          gespeicherten personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format erhalten
          können oder die Übermittlung an einen anderen Verantwortlichen verlangen können.
          <br/><br/>
        </li>
        <li><strong>Recht auf Beschwerde</strong><br/>
          Sie haben ein Recht auf Beschwerde bei einer Aufsichtsbehörde. In der Regel können Sie sich hierfür an die
          Aufsichtsbehörde insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts
          des mutmaßlichen Verstoßes wenden.
          <br/><br/>
        </li>
      </ol>
      <br/>
      <h2>Datensicherheit</h2>
      <p>Um alle personenbezogen Daten, die an uns übermittelt werden, zu schützen und um sicherzustellen, dass die
        Datenschutzvorschriften von uns, aber auch unseren externen Dienstleistern eingehalten werden, haben wir
        geeignete technische und organisatorische Sicherheitsmaßnahmen getroffen. Deshalb werden unter anderem alle
        Daten zwischen Ihrem Browser und unserem Server über eine sichere SSL-Verbindung verschlüsselt übertragen.</p>
      <br/>

      <br/>
      <h2>Stand: 18.08.2019</h2><p>Quelle: <a href="https://www.juraforum.de/impressum-generator/">Muster-Datenschutzerklärung
      von JuraForum.de</a></p>


    </div>
  );
};

export default PrivacyPolicy;