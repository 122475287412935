import React from 'react';
import GameLoader from '../../../game/GameLoader';

const PrevBtn = (props) => {

    const Attr = {
        onClick: (props.enabled)
            ? () => GameLoader.stepBack()
            : () => false,
        disabled: !props.enabled
    };
    return <button
        className="Btn icon-back"
        title="Do next move"
        {...Attr}/>
};

export default PrevBtn;