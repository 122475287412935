/**
 * Scenario Edge
 *
 *   0 2 5 9 . . . .
 *   1 4 8 . . . . .
 *   3 7 . . . . . .
 *   6 . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *
 */

export default class ScenarioEdge {
    name = 'edge';
    weight = 10;
    schemes;

    constructor() {
        this.schemes = [
            {
                a: [ 0, 1, 8, 2, 9,16, 3,10,17,24],
                b: [ 0, 8, 1,16, 9, 2,24,17,10, 3]
            },
            {
                a: [ 7,15, 6,23,14, 5,31,22,13, 4],
                b: [ 7, 6,15, 5,14,23, 4,13,22,31]
            },
            {
                a: [63,62,55,61,54,47,60,53,46,39],
                b: [63,55,62,47,54,61,39,46,53,60]
            },
            {
                a: [56,48,57,40,49,58,32,41,50,59],
                b: [56,57,48,58,49,40,59,50,41,32]
            },
        ];
    }

}