import React, {Component} from 'react';
import {connect} from 'react-redux';
import {indexToAlphaNum, ucfirst} from "../../game/tools";
import './TipsTable.scss';

class TipsTable extends Component {

    rate(result) {
        if (result.length === 2) {
            const denominator = result[0] + result[1];
            return Math.round(result[0] / denominator * 100) + '%';
        } else return '';
    }

    render() {

        if(this.props.devMode) {

            return this.props.tipsTable.map((move) => {
                const rows = move.appears.map((row, tr) => {
                    const result = (typeof row.result !== 'undefined')
                        ? Object.keys(row.result).map(key => {
                                return row.result[key];
                            })
                        : [];
                    const cells = (result.length)
                        ? result.map((res, td) => (<td key={td}>{res}</td>))
                        : [(<td key={tr+'-1'}>./.</td>), (<td key={tr+'-2'}>./.</td>)];
                    return (<tr key={tr}>
                        <td>{ucfirst(row.scheme)}</td>
                        <td>{row.search}</td>
                        <td>{row.moveAlias}</td>
                        {cells}
                        <td>{this.rate(result)}</td>
                    </tr>)
                });

                const table = (<table>
                    <thead>
                    <tr>
                        <th>Scheme</th>
                        <th>Disposition</th>
                        <th>Alias</th>
                        <th>Win</th>
                        <th>Lose</th>
                        <th>%</th>
                    </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </table>);

                return (<article key={move.move} className="TipsTable">
                    <header><b>{ indexToAlphaNum(move.move) }</b></header>
                    <div className="main">{table}</div>
                </article>);
            });
        } else
            return '';
    }
}

const mapStateToProps = state => {
    return {
        devMode: state.devMode,
        tipsTable: state.tipsTable
    }
};

export default connect(mapStateToProps)(TipsTable)
