import store from "../store/store";
import { getAllPossibleMoves, setToken, boardUpdated } from "./game";
import History from './game_history';
import { posToXy, alphaNumToIndex } from './tools';
import database from '../store/database';

class GameLoader {
    loggedIn;
    versionImport;
    gameMode;
    loadedGame;
    allMoveOptions;
    history;

    constructor() {
        const state = store.getState();
        this.loggedIn = state.loggedIn;
        this.gameMode = state.gameMode;
        this.versionImport = state.versionImport;
        this.loadedGame = { ...state.loadedGame };
        this.accounts = [...state.accounts].map(acc => {return {...acc}});
        // Get all available moves from Disposition.
        this.allMoveOptions = getAllPossibleMoves(state.disposition, state.toMove);
    }

    /**
     * Returns the next move in a training game gotten from store.
     *
     * @returns {int|boolean}
     */
    getNextMove() {
        if (this.loadedGame.moves.length >= this.loadedGame.position) {
            return this.loadedGame.moves[this.loadedGame.position++];
        } else {
            return false
        }
    }

    /**
     * Go on step back in history.
     */
    static stepBack() {
        const history = new History();
        history.rewindHistory(-1);
    }

    /**
     * Inits next move in a training game.
     */
    initMove() {
        const nextMove = this.getNextMove();
        if(nextMove !== false && this.allMoveOptions.indexOf(nextMove) !== -1) {
            const xy = posToXy(nextMove);
            setToken(xy.x, xy.y);
        }
    }

    /**
     * Calculates a move array from a move string (f5d6c3d3c4f3f4...).
     *
     * @returns {number[]}
     */
    loadGame(recent_id) {
        let query = {version_import: this.versionImport};
        if (typeof recent_id !== 'undefined') {
            query.recent_id = recent_id;
        }
        const db = new database(query);
        db.loadGame((data) => {

            if (data.status < 400 && typeof data.data.moves !== 'undefined') {

                if (this.gameMode === 'train'
                    && data.data.versionImport === this.versionImport
                ) {
                    // The master games training has finished and the self training shall start.
                    store.dispatch({
                        type: 'START_SELF_TRAIN',
                        loggedIn: this.loggedIn,
                        gameMode: 'train',
                        trainMode: 'self'
                    });
                    return false;
                }

                // Get moves.
                this.loadedGame.moves = alphaNumToIndex(data.data.moves);
                this.loadedGame.id = data.data.id;

                // Modify accounts.
                const white = {
                    name: (data.data.white) ? data.data.white : 'White',
                    disabled: true,
                    computed: false
                };
                const black = {
                    name: (data.data.black) ? data.data.black : 'Black',
                    disabled: true,
                    computed: false
                };
                this.accounts[0] = { ...this.accounts[0], ...white };
                this.accounts[1] = { ...this.accounts[1], ...black };

                const storeMoves = (typeof data.data.versionImport !== 'undefined'
                    && data.data.versionImport < this.versionImport
                );

                store.dispatch({
                    type: 'CREATE_GAME_MOVES',
                    loadedGame: this.loadedGame,
                    accounts: this.accounts,
                    storeMoves: storeMoves
                });

                // Start to play if gameMode is 'train'.
                if(this.gameMode === 'train') {
                    boardUpdated();
                }
            }
        });
    }
}

export default GameLoader;