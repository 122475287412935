/**
 * Scenario Side
 *
 *   0 1 2 3 4 5 6 7
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *   . . . . . . . .
 *
 */

export default class ScenarioSide {
    name = 'side';
    weight = 5;
    schemes;

    constructor() {
        this.schemes = [
            {
                a: [ 0, 1, 2, 3, 4, 5, 6, 7],
                b: [ 7, 6, 5, 4, 3, 2, 1, 0]
            },
            {
                a: [ 7,15,23,31,39,47,55,63],
                b: [63,55,47,39,31,23,15, 7]
            },
            {
                a: [63,62,61,60,59,58,57,56],
                b: [56,57,58,59,60,61,62,63]
            },
            {
                a: [56,48,40,32,24,16, 8, 0],
                b: [ 0, 8,16,24,32,40,48,56]
            },
        ];
    }

}
