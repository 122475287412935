import React, { Component } from 'react';

import './HistItem.scss';

class HistItem extends Component {

    render = () => {
        let y = Math.floor(this.props.move/8) + 1;
        let x = "ABCDEFGH".charAt(this.props.move%8);
        const position = x + y;
        let classes = ['HistItem'];
        if (this.props.toMove === 1)
            classes.push('White');
        else
            classes.push('Black');

        return <li
            onClick={this.props.clicked}
            className={classes.join(' ')}>{position}</li>
    }
}

export default HistItem;
