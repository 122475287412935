import React from 'react';
import GameLoader from '../../../game/GameLoader';

const initNext = () => {
    const gameLoader = new GameLoader();
    gameLoader.initMove();
};

const NextBtn = (props) => {

    const Attr = {
        onClick: (props.enabled)
            ? () => initNext()
            : () => false,
        disabled: !props.enabled
    };
    return <button
        className="Btn icon-forward"
        title="Do next move"
        {...Attr}/>
};

export default NextBtn;