import React, { Component } from 'react';
import { connect } from 'react-redux';
import GameLoader from "../../../game/GameLoader";

class Training extends Component {

    loadGame() {
        this.props.startTrain();
        const gameLoader = new GameLoader();
        gameLoader.loadGame();
    }

    render() {
        if(this.props.loggedIn) {

            // Set Play/Stop icon and mode.
            let text = {__html: 'Train &#9655;'};
            let action = () => this.loadGame();

            if(this.props.gameMode === 'train') {
                text = {__html: 'Train &#9723;'};
                action = () => this.props.stopTrain();
            } else {
                clearTimeout(this.props.timer);
            }

            return <button className="StartTrain Btn"
                           onClick={action}
                           dangerouslySetInnerHTML={text} />
        } else
            return null;
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.loggedIn,
        gameMode: state.gameMode,
        trainMode: state.trainMode,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        startTrain: () => {
            dispatch({
                type: 'START_TRAIN',
                gameMode: 'train',
                trainMode: 'masters'
            })
        },
        stopTrain: () => {
            dispatch({
                type: 'STOP_TRAIN',
                gameMode: 'play',
                trainMode: 'stopped'
            })
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Training);