import React, {Component} from 'react';

import './PlayerGroup.scss'

class PlayerGroup extends Component {



    render = () => {



        const status = <div className={['Status', ]}>{this.props.status}</div>;


        const checkBoxComp = !this.props.disabled
            ? (<label className="form-element fe-checkbox">
                <input type="checkbox"
                       name={'account_' + this.props.id}
                       onChange={this.props.clicked}
                       checked={this.props.computed && !this.props.disabled}
                />
                <span className={this.props.computed ? 'icon-calc' : 'icon-user' }/>
            </label>)
            : (<label className="icon-crown">{this.props.name}</label>);
        const color = this.props.id === 1 ? 'White' : 'Black';
        return <div className={['PlayerGroup', color].join(' ')}>
            {status}
            {checkBoxComp}
        </div>
    }
}

export default PlayerGroup;